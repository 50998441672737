#service,
#service-teaser,
#service-timeline,
#trainee {
	.icon:before,
	&:before {
		background: color(headline);
		width: 2.5em;
		height: 2.5em;
		line-height: 2.5em;
		border-radius: 50%;
		text-align: center;
		color: color(silk);
		z-index: 1;
		font-size: rem(20px);
		display: block;
		position: absolute;

		html.contrast-intensify & {
			background: color(primary);
		}
	}
	&:before {
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	.icon {
		position: relative;
		flex-shrink: 0;
		margin: rem($base-gap) rem($base-gap) ;
		margin-right: rem(30px);
		
		@include breakpoint(full) {
			margin-right: rem(60px);
		}
		
		&:before {
			bottom: 0;
			right: 0;
		}
	}
}

#service {
	background: linear-gradient(to bottom, color(silk) 98%, color(pale));

	&, &-teaser {
		position: relative;
		overflow: hidden;
		z-index: 1;
		width: 100%;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			transform: translate(25%, -25%);
			width: 38vw;
			height: 38vw;
			background: color(pale);
			border-radius: 50%;
			z-index: -1;
			box-shadow:
				0 0 0 19vw color-lightness(pale, 1%),
				0 0 0 38vw color-lightness(pale, 2%);
	
			@include breakpoint(cut) {
				right: 50%;
				transform: translate(calc(#{rem($row-max-width / 2)} + 25%), -25%);
				width: rem(630px);
				height: rem(630px);
				box-shadow:
					0 0 0 rem(315px) color-lightness(pale, 1%),
					0 0 0 rem(630px) color-lightness(pale, 2%);
			}
		}
	
		> .row {
			@include space(230px);
		}
	}

	&-teaser {
		background: color(silk);
		
		h1 {
			br {
				display: none;
	
				@include breakpoint(micro) {
					@include breakpoint(tiny, max) {
						display: block;
					}
				}
			}
		}
		section {
			display: flex;
			flex-wrap: wrap;
			align-content: flex-start;
			padding: rem($base-line-height) rem($base-gap);
			
			@include breakpoint(giant) {
				flex-wrap: nowrap;
				align-items: center;
			}
			@include breakpoint(huge) {	
				padding: rem(50px) rem(60px) rem(50px) rem($base-gap);
			}
	
			@include breakpoint(medium, max) {
				h2 {
					font-size: rem(20px);
				}
				p {
					font-size: rem(16px);
					line-height: 1.45em;
				}
			}
		}
	}

	&-timeline {
		@include space(190px);
		margin: 0 0 5rem -1rem !important;
		hyphens: auto;
		
		@include breakpoint(huge) {
			margin: 0 0 0 -1rem !important;
		}

		&:before {
			animation: pulse 3s ease-in-out infinite alternate;
		}

		#timeLine {
			position: absolute;
			width: 1px;
			background: color(dark);
			padding:  0;
			top: rem(77px);
			height: calc(100% - #{rem(77px * 2)});
			left: 50%;

			&:before, 
			&:after {
				position: absolute;
				top: 100%;
				left: 0;
				display: block;
				transform: translateX(-50%);
				font-weight: 700;
			}
			&:before {
				content: 'w';
				background: color(headline);
				width: 2em;
				height: 2em;
				line-height: 2em;
				border-radius: 50%;
				text-align: center;
				color: color(silk);
				z-index: 1;
				margin-top: 1rem;
				font-size: rem(25px);

				html.contrast-intensify & {
					background: color(dark);
				}
			}
			&:after {
				content: 'Dein Erfolg bei wwwe';
				white-space: nowrap;
				color: color(headline);
				margin-top: 4.3rem;

				@include breakpoint(huge) {
					font-size: rem(20px);
				}

				html.contrast-intensify & {
					color: color(dark);
				}
			}
		}
		
		.box {
			background: color(faint);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			width: 100%;
			padding: 2rem;
			
			@include breakpoint(huge, max) {
				justify-content: center;
			}
			@include breakpoint(giant) {
				padding: 2rem 2.75rem;
			}
			@include breakpoint(full) {
				padding: 2rem 4.8rem;
			}

			html.contrast-intensify & {
				border: $base-border;
			}

			.icon {
				@include breakpoint(medium, max) {
					margin-top: 0;
				}
				@include breakpoint(full) {
					margin-right: rem(40px);
				}
			}
		}
		section {
			position: relative;
			margin-bottom: rem(111px);
			z-index: 1;
			transition: 1.3s ease-in-out;

			@include breakpoint(medium, max) {
				padding-top: 3rem;
				padding-bottom: 3rem;
				background: color(silk);
			}

			&:not(.isOnScreen) {
				transform: translateY(4rem);
				opacity: 0;
				visibility: hidden;
			} 
			
			&:before,
			&:after {
				display: block;
				position: absolute;
				top: rem(110px);
				background: color(dark);
				
				@include breakpoint(medium) {
					content: '';
				}
			}
			&:before {
				width: rem(8px);
				height: rem(8px);
				border-radius: 50%;
				transform: translate(0, -50%);
				z-index: 1;

				@include breakpoint(giant) {
					width: rem(13px);
					height: rem(13px);
				}
			}
			&:after {
				width: rem($base-gap * 1.5 - 5);
				height: 1px;
				
				@include breakpoint(giant) {
					width: rem(40px);
				}
				@include breakpoint(huge) {
					width: rem(100px);
				}
				@include breakpoint(cut) {
					width: rem(130px);
				}
			}

			h2 {
				padding-top: 1.9em;
			}
			.timelineTags {
				font-weight: bold;
				position: relative;
				padding-left: rem(37px);
				
				@include breakpoint(tiny) {
					padding-left: rem(60px);
				}
				@include breakpoint(small) {
					padding-left: rem(90px);
				}
				@include breakpoint(medium) {
					padding-left: rem(37px);
				}
				@include breakpoint(large) {
					padding-left: rem(60px);
				}
				@include breakpoint(huge) {
					padding-left: rem(90px);
				}

				&:before {
					content: '';
					background: color(dark);
					width: rem(25px);
					height: 1px;
					position: absolute;
					left: 0;
					top: 0.75em;
					display: block;

					@include breakpoint(tiny) {
						width: rem(40px);
					}
					@include breakpoint(small) {
						width: rem(64px);
					}
					@include breakpoint(medium) {
						width: rem(25px);
					}
					@include breakpoint(large) {
						width: rem(40px);
					}
					@include breakpoint(huge) {
						width: rem(64px);
					}
				}
				
				li {
					display: inline;

					&:not(:last-child):after {
						content: ', ';
					}
				}
			}
		}

		> .col {
			@include breakpoint(medium) {
				&:first-of-type {
					padding-right: 0;
					
					section {
						padding-right: rem($base-gap * 3);

						&:before {
							right: rem($base-gap * 1.5);
						}
						&:after {
							right: 0;
						}
						
						@include breakpoint(giant) {
							padding-right: rem(84px);

							&:before {
								right: rem(45px);
							}
						}
						@include breakpoint(huge) {
							padding-right: rem(150px);

							&:before {
								right: rem(105px);
							}
						}
						@include breakpoint(cut) {
							padding-right: rem(208px);

							&:before {
								right: rem(135px);
							}
						}
					}
				}
				&:last-of-type {
					padding-left: 0;
					padding-top: rem(250px);

					section {
						padding-left: rem($base-gap * 3);

						&:before {
							left: rem($base-gap * 1.5);
						}
						&:after {
							left: 0;
						}

						@include breakpoint(giant) {
							padding-left: rem(84px);

							&:before {
								left: rem(45px);
							}
						}
						@include breakpoint(huge) {
							padding-left: rem(150px);

							&:before {
								left: rem(105px);
							}
						}
						@include breakpoint(cut) {
							padding-left: rem(208px);

							&:before {
								left: rem(135px);
							}
						}
	
						.box {
							border-radius: 0 0 rem(80px) 0;
		
							@include breakpoint(huge) {
								border-radius: 0 0 rem(150px) 0;
							}
	
						}
					}
				}
			}
		}
		
		figcaption {
			font-weight: bold;
			color: color(dark);
			font-size: rem(20px);
			line-height: 1.45em;
			
			em {
				line-height: 1.45em;
				display: block;
				font-weight: 500;
				font-size: 0.8em;
			}
		}
	}
}

@keyframes pulse {
	from {
		transform: translateX(-50%) scale(1);
	}
	to {
		transform: translateX(-50%) scale(1.2);
	}
}