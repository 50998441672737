.wwwe {
	// color: color(primary);
	text-transform: lowercase;
	font-weight: 700;
}

.spaceLineHeight {
	padding-top: rem($base-line-height);
} 
.spaceSmall {
	@include space(55px);
} 
.space {
	@include space(132px);
}
.spaceOffsetNav {
	@include space(202px);
}
.space100 {
	@include space(100px);
}
.noSpaceTop {
	padding-top: 0 !important;
}
.noSpaceBottom {
	padding-bottom: 0 !important;
}

.marginBottomGap {
	margin-bottom: rem($base-gap * 2);
}
.marginBottom {
	margin-bottom: rem($base-line-height);
}
.marginBottomBig {
	margin-bottom: rem($base-line-height);
	
	@include breakpoint(small) {
		margin-bottom: rem($base-line-height * 2);
	}
	@include breakpoint(giant) {
		margin-bottom: rem(110px);
	}
}
.marginBottomSmall {
	margin-bottom: rem($base-line-height / 2);
}
.marginBottomSmallSelf {
	margin-bottom: 0.2em;
}
.marginBottomNone {
	margin-bottom: 0;
}

.color-middle {
	color: color(middle);
}

.smallFont {
	font-size: rem(14px);
	line-height: 1.4em;
}

.bg-light {
	background: color(silk);
}
.bg-bgGreen {
	background: color(primary-soft);
}
.bg-olive {
	background: color(olives);
}
.bg-gradient-grey {
	background: linear-gradient(to bottom, color(silk, .05) 10%, color(silk) 49%);
}
.bg-benefits-gradient {
	background: linear-gradient(to bottom, color(border), color(flat));
}

.height100 {
	height: 100%;
}

.self-stretch {
	align-self: stretch;
}

.imgCover {
	object-fit: cover;
}

div.box {
	background: #F6F8F8;
	padding: 2rem;
	font-weight: 700;

	@include breakpoint(full) {
		padding: rem(45px) rem(56px);
	}
	@include breakpoint(cut) {
		padding: rem(56px) rem(83px);
	}

	> *:last-child {
		margin-bottom: 0 !important;
	}
}

.flex {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.columnNowrap {
	flex-flow: column nowrap;
}

.self-stretch {
	align-self: stretch;
}

.grow {
	flex-grow: 1;
}

.flexNone {
	flex: none;
}

.contentColumns {
	columns: 3 rem(300px);
	column-gap: rem(70px);

	> * {
		break-inside: avoid;
	} 
}

.imgDrop {
	border-radius: 38% 63% 2rem 2rem;
	overflow: hidden;
	display: inline-block;
	transform: rotate(32deg);
	transition: 1.2s;
	width: rem(114px);
	height: rem(114px);

	&:hover {
		border-radius: 33% 40% 5rem 3rem;
	}
		
	img {
		transform: rotate(-32deg) scale(1.3);
	}
}

.btn {
	@extend %animatedTransform;
	background: color(headline);
	border: none;
	color: color(silk);
	font-weight: 700;
	cursor: pointer;
	display: inline-block;
	font-size: rem($base-font-size);
	line-height: 1.35em;
	padding: 1.2em 2.2em;
	border-radius: 9em;
	text-align: center;
	text-decoration: none;
	font-family: $main-font;
	box-shadow: 0 0 rem(30px) color(dark, .15);

	&.btnWhite {
		background: color(light);
		color: color(secondary);
	}
	&.btnBlue {
		background: color(headline);
		color: color(soft);
	}
	&.btnPrime {
		background: color(primary);
		color: color(headline);
	}

	&, &.btnwhite {
		&:hover, &:active, &:focus {
			background: color(secondary);
			color: color(silk);
		}
	}

	&[class*="icon"] {
		&:before {
			margin-right: 1em;
			vertical-align: -0.05em;
			color: color(light, .5);
		}
	}

	html.contrast-intensify & {
		text-decoration: underline !important;
		
		&:hover,
		&:focus {
			text-decoration: none !important;
		}
	}
}

.grayscale {
	filter: grayscale(100%);
}

.highlight,
.highlightMiddle,
.highlightBig,
.highlight-clamp-12 {
	font-size: rem(16px);
	line-height: 1.55em;
	font-weight: 500;
	letter-spacing: 0.04em;

	@include breakpoint(tiny) {
		font-size: rem(18px);
	}
	@include breakpoint(large) {
		font-size: rem(20px);
	}
}
.highlightMiddle {
	@include breakpoint(giant) {
		font-size: rem(22px);
	}
}
.highlightBig {
	@include breakpoint(giant) {
		font-size: rem(24px);
	}
}

.smallSize {
	font-size: rem(14px);
	line-height: 1.4em;
}

.row.smallGrid {
	&, > .col {
		@include breakpoint(medium, max) {
			padding-left: rem($base-gap / 2);
			padding-right: rem($base-gap / 2);
		}
	}
	> .col {
		margin-bottom: rem($base-gap);
		
		@include breakpoint(medium) {
			margin-bottom: rem($base-gap * 2);
		}
	}
}

.rCornerThis {
	margin-bottom: rem(-60px);
	
	&-bl {
		border-radius: 0 0 0 rem(60px);

		+ * {
			padding-top: rem(60px);
		}
	}

	@include breakpoint(medium) {
		margin-bottom: rem(-84px);
	
		&-bl {
			border-radius: 0 0 0 rem(84px);

			+ * {
				padding-top: rem(84px);
			}
		}
	}
}
.rCornerThisSimple {
	border-radius: 0  0 rem(60px) rem(60px);
	
	@include breakpoint(medium) {
		border-radius: 0 0 rem(84px) rem(84px);
	}
}

.rCornerNext {
	padding-bottom: rem(60px);
	
	&-tr + * {
		border-radius: 0 rem(60px) 0 0;
		margin-top: rem(-60px);
	}
	
	@include breakpoint(medium) {
		padding-bottom: rem(84px);
		
		&-tr + * {
			border-radius: 0 rem(84px) 0 0;
			margin-top: rem(-84px);
		}
	}
}
.rCrop {
	border-radius: 0 0 0 rem(60px);
	overflow: hidden;
	display: inline-block;
	
	@include breakpoint(medium) {
		border-radius: 0 0 0 rem(84px);
	}
	@include breakpoint(huge) {
		border-radius: 0 0 0 rem(150px);
	}

	&-lt {
		border-radius: rem(60px) 0 0 0;

		@include breakpoint(medium) {
			border-radius: rem(84px) 0 0 0;
		}
		@include breakpoint(huge) {
			border-radius: rem(150px) 0 0 0;
		}
	}
}

.iconBox {
	position: relative;
	
	&:before {
		@extend .icon;
		display: block;
		background: color(headline);
		width: 2.5em;
		height: 2.5em;
		line-height: 2.5em;
		color: color(silk);
		border-radius: 50%;
		text-align: center;
		margin: 0 1em 0.7em 0;

		@include breakpoint(huge) {
			font-size: rem(20px);
		}
	}
	@include breakpoint(large) {
		padding-left: rem(80px);

		&:before {
			position: absolute;
			left: rem($base-gap);
			top: 0;
		}
	}
	@include breakpoint(huge) {
		padding-left: rem(100px);
	}
}

.circledBottomLight {
	position: relative;
	background: linear-gradient(to bottom, color(light), color(silk));
	z-index: 1;
    overflow: hidden;    

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50%);
		width: 38vw;
		height: 38vw;
		background: #f1f2f5;
		border-radius: 50%;
		z-index: -2;
		box-shadow:
			0 0 0 19vw #f4f5f8,
			0 0 0 38vw #f7f8f9;

		@include breakpoint(cut) {
			width: rem(630px);
			height: rem(630px);
			box-shadow:
				0 0 0 rem(315px) #f4f5f8,
				0 0 0 rem(630px) #f7f8f9;
		}
	}
}

.sticky {
	&, &.transformImg {
		@include breakpoint(large) {
			position: sticky;
			top: 20vh;
		}
	}
}

.row.seperaterBottom {
	&:after {
		content: '';
		border-bottom: $base-border;
		left: rem($base-gap);
		right: rem($base-gap);
		position: absolute;
		bottom: 0;
	}
}

.inlineBlock {
	display: inline-block;
}

.overflowHidden {
	overflow: hidden;
}

.relative {
	position: relative;
}

.clear {
	clear: both;
	float: none;
}

.clearfix {
	@include pie-clearfix;
}

.hidden {
	display: none !important;
}

.hideText {
	@include hideText();
	display: inline-block;
	overflow: hidden;
	width: 0;
}

.floatLeft {
	float: left;
}

.floatLeft img,
img.floatLeft {
	margin-right: 2rem;
	margin-bottom: 1.5rem;
	max-width: 30%;

	+ * {
		hyphens: auto;
	}
}

.blog-gallery {
	display: grid;
	grid: auto / repeat(auto-fit, minmax(147px, 1fr));
	gap: rem($base-gap);
	margin-bottom: rem($base-line-height * 2);

	// @include breakpoint(medium) {
	// 	grid: auto / repeat(4, 1fr);
	// }

	br,
	*:not(img):empty {
		display: none;
	}
	img {
		width: 100% !important;
	}
}

.floatRight {
	float: right;
}

img.floatRight {
	margin-left: 1rem;
	margin-bottom: 1.5rem;
	max-width: 30%;
}

.fluid {
	height: auto;
	width: 100%;
}

.nonFluid {
	width: auto !important;
}

.alignLeft {
	text-align: left;
}

.alignRight {
	text-align: right;
}

.alignCenter {
	text-align: center;
}

.wide {
	width: 100%;
	display: block;
}

/* 16:9 video resolutions */
.videoContainer {
	&:not(.videoTag) {
		position: relative;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;

		iframe {
			border: none;
			height: 100%;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
		}
	}

	video {
		width: 100%;
		height: auto;
	}
}
.googleMaps {
	@extend .videoContainer;

	@include breakpoint(medium) {
		height: rem(400px);
	}
}

body {
	&:before {
		$content: "default:" + $bp-context;
		width: str_length($content) * 6pt + $base-gap * 2;

		@each $point, $width in $grid-map {
			$content: $content + "...." + $point + ":" + nth(map-get(map-get($grid-map, $point), width), 1);

			@include breakpoint($point) {
				width: str_length($content) * 6pt + $base-gap * 2;
			}
		}

		content: "#{$content}";
		display: none !important; /* Prevent from displaying. */
	}

	&.devmode:before,
	&.debug:before {
		background: color(alert);
		border-color: color(alert);
		border-style: solid;
		border-width: 0 rem($base-gap);
		color: color(silk);
		display: block !important;
		font-family: Courier;
		font-size: 10pt;
		left: 0;
		line-height: 2.5;
		overflow: hidden;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		white-space: nowrap;
		z-index: 99999;
	}
	&:hover:before {
		opacity: 0.5;
		pointer-events: none;
	}
}

// Animations
%animatedTransform {
	transition: 0.3s;
}

html.inProgress {
	pointer-events: none;
	&:before {
		content:"";
		width: 100vw;
		height: 100vh;
		background: color(noir, .5);
		position: fixed;
		top:0;
		left:0;
		z-index:99999998
	}
	&:after {
		content:"";
		position: fixed;
		top: 0;
		height: 0;
		background: url('/images/layout/loading.svg') no-repeat center center;
		height: 100vh;
		width: 100vw;
		z-index:99999999;
		filter:invert(100%);
	}
}