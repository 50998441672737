#awards {
    background: #edeef0;
    padding: rem($base-line-height * 1.5) 0;
    overflow: hidden;

    @include breakpoint(small) {
        padding: rem($base-line-height * 2.25) 0;
    }
    @include breakpoint(large) {
        padding: rem($base-line-height * 3) 0;
    }
    @include breakpoint(full) {
        padding: rem(110px) 0;
    }

    html.contrast-intensify & {
        background: color(light);
        border-top: $base-border;
    }

    .swiper-wrapper {
        transition-timing-function: linear;
        display: flex;
        width: 100%;
    }
    .swiper-slide {
        padding: 0 rem(30px);
        width: auto;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        
        @include breakpoint(small) {
            padding: 0 rem(42px);
        }
        @include breakpoint(large) {
            padding: 0 rem(55px);
        }
    }
}