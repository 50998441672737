// Define some variables for collapser usage
$collapser-class: ".collapser";
$collapser-gaps-lr: 1.2rem;
$collapser-gaps-tb: 1rem;
$collapser-color: color(action);
$collapser-dark: color(silk);

// Dont Edit Inside the mixin, overwrite styles in @include collapser
@mixin collapser {

    #{$collapser-class} {
        background: $collapser-color;
        position: relative;
        cursor: pointer;
        color: color(silk);
        transition: 0.4s ease-in-out;
        border-radius: 0 0 3em 0 !important;
        line-height: 1.4em;
        padding: 1rem;
        padding-right: 3.5rem !important;
        margin: 0;
        
        @include breakpoint(micro) {
            padding: 2rem;
        }
        @include breakpoint(small) {
            padding: 2rem 3rem;
            padding-right: 4rem !important;
        }

        &:not(.collapser-initialized) {
            background: none;
            padding: 0;

            &:after, &:before {
                display:none;
            }
        }

        &:hover, &:focus, &:active {
            background: color(hover);
        }

        &:after, &:before {
            content:"";
            position: absolute;
            width: 1em;
            height: 2px;
            background: $collapser-dark;
            right: 2rem;
            transition: all 300ms;
            
            @include breakpoint(small) {
                right: 2.5rem;
            }
        }
        &:after {
            top: 50%;
        }
        &:before {
            transform: rotate(90deg);
            top: 50%;
        }

        + * {
            overflow: hidden;
            transition: max-height 300ms;
            padding: 0 1rem;
            border-left: $base-border;
            // border-left: 0.2rem solid #ddd;

            @include breakpoint(micro) {
                padding: 0 2rem;
            }
            @include breakpoint(small) {
                padding: 0 3rem;
            }

            &.limited {
                overflow-y: scroll;
                margin-bottom: 1rem;
            }

            > * {
                opacity: 0;
                visibility: hidden;
                transition: 0.4s 0.1s ease-in-out;

                &:first-child {
                    padding-top: rem($base-line-height * 1.5);
                }
            }
        }
        &.active {
            &:before {
                transform: rotate(-135deg);
            }
            &:after {
                transform: rotate(-45deg);
            }

            + * {
                > * {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        @content;
    }
}
@include collapser;