#brands {
    display: flex;
    flex-flow: row wrap;
    padding: rem($base-line-height * 0.5) rem($base-gap - 5px) rem($base-line-height * 1.5);

    @include breakpoint(medium) {
        padding: rem($base-line-height * 0.5) rem($base-gap - 5px) rem(80px);
    }
    @include breakpoint(huge) {
        padding: rem(80px) rem($base-gap - 5px) rem(132px);
    }

    li {
        padding: rem(5px);
        width: 100%;
        max-width: 50%;
        flex-grow: 1;
        
        @include breakpoint(tiny) {
            max-width: 33.33333%;
        }
        @include breakpoint(giant) {
            max-width: 25%;
        } 
        @include breakpoint(full) {
            max-width: 20%;
        }

        #footer & {
            @include breakpoint(medium) {
                max-width: 25%;
            }
            @include breakpoint(end) {
                flex-grow: 0;
                width: auto;
                max-width: rem(193px);
            }
        }

        a {
            height: rem(70px);
            padding: 1.1rem .1rem;
            display: block;
            background: color(medium, .3);
            text-align: center;
            transition: 0.3s;

            html.contrast-intensify & {
                background: color(light);
                border: $base-border;
            }

            @include breakpoint(medium) {
                height: rem(140px);
                padding: 2.6rem .1rem;

                &[href*="united-media"] {
                    padding: 1.5rem .1rem;
                }
            }

            #footer & {
                @include breakpoint(medium) {
                    height: rem(86px);
                    padding: 1.6rem .1rem;
                }

                &[href*="united-media"] {
                    padding: 0.9rem .1rem;
                }
                
                @include breakpoint(cut) {
                    padding: 1.6rem;

                    &[href*="united-media"] {
                        padding: 0.9rem;
                    }
                }

            }


            &:hover, &:focus, &:active {
                background: color(medium, .15);

                html.contrast-intensify & {
                    background: color-lightness(light, -10%);
                }
            }
        }
        img {
            object-fit: contain;
            height: 100%;
            mix-blend-mode: darken;
            width: rem(77px); 

            @include breakpoint(medium) {
                width: rem(90px);
            }
            
            #content & {
                @include breakpoint(giant) {
                    width: rem(120px);
                }
            }
        }
    }
}