// Form styles
.form-default,
form.default {
	// overflow: hidden;
	width: 100%;

	&.disabled {
		input, textarea, select, button, label {
			pointer-events: none;
			opacity:0.5;

			a
			{
				pointer-events: all;
			}
		}
	}

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: color(dark);
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color: color(dark);
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color: color(dark) !important;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color: color(dark);
	}

	fieldset {
		border: none;
		margin: 0 0 rem($base-line-height) 0;
	}

	legend {
		@extend .h2;
	}

	label {
		color: inherit;
		display: block;
		padding: 1rem 0 0.3em;
		font-size: rem(12px);
		font-weight: 700;
		color: color(dark);
		letter-spacing: 0.03em;
		line-height: 1.4em;

		@include breakpoint(large) {
			font-size: rem(14px);
		}
		
		small {
			color: color(dark);
			display: inline-block;
			line-height: 0.5rem;
		}

		html.contrast-intensify & {
			color: color(dark);
			 
			small {
				color: color(alert);
			}
		}
	}
	.checkboxesbox label {
		padding-bottom: 0.6rem;
	}

	small {
		line-height: 1rem;
		display: inline-block;
	}

	.label-input {
		margin-bottom: rem($base-line-height);
		
		&.checkboxesbox,
		&.radiosbox {
			display: flex;
			flex-wrap: wrap;
			
			label {
				width: 100%;
				
				&.sublabel {
					order: 1;
					flex-wrap: nowrap;
				}
			}
		}

		.sublabel {
			width: 100%;
			font-weight: 400;
			color: color(dark);
			letter-spacing: 0;
			margin-bottom: rem($base-line-height);
			font-size: rem(14px);

			@include breakpoint(large) {
				font-size: rem($base-font-size);
			}
		}		
		&.filebox {
			margin-bottom: rem($base-line-height);

			.upload-btn {
				width: 100%;

				.sublabel {
					display: none;
				}
			}
		}
		// &:not(.checkboxesbox):not(.radiosbox):not(.filebox) {
		// 	position: relative;
		// 	margin-bottom: rem($base-line-height);
	
		// 	label {
				// + span {
				// 	display: block;
				// 	height: 1.7rem;
				// 	margin-top: rem(63px) - 1.7rem;
				// 	overflow: hidden;
				// 	position: relative;

				// 	input,
				// 	textarea,
				// 	&:after {
				// 		position: absolute;
				// 		bottom: 0;
				// 		left: 0;
				// 		width: 100%;
				// 		display: block;
				// 		border-radius: 1.8rem;
				// 	}
				// 	&:after {
				// 		content: '';
				// 		border: 2px solid color(medium, .6);
				// 		border-top: 2px solid transparent;
				// 		height: 3.5rem;
				// 		transition: 0.3s ease-in-out;
				// 	}
				// }
		// 	}
		// }
		input, select {
			&:not([type="checkbox"]):not([type="radio"]) {
				width: 100%;
				margin: 0;
			}
		}
	}

	.has-error {
		.error-message {
			color: #c12c2c;
			font-size: .8em;
			display: block;
			width: 100%;
			overflow: hidden;
			transition: .3s;
			line-height: 2em;
			height: 2em;
		}
		[name] {
			&:invalid {
				border-color: #a93c3c;
			}
			&:valid ~ .error-message {
				height: 0;
				opacity: 0;
			}
		}
	}

	[type="text"],
	[type="tel"],
	[type="email"],
	[type="file"],
	.input,
	input,
	textarea,
	select {
		transition: 1s;
		background: none;
		border: none;
		border-bottom: 2px solid color(medium, .6);
		color: color(secondary);
		display: block;
		font-family: $main-font;
		font-size: rem(14px);
		line-height: 1.4em;
		margin: 0 0 0.5rem 0;
		padding: 0.8em 0;
		position: relative;
		resize: none;
		width: 100%;
		accent-color: color(primary);
		border-radius: 0;

		@include breakpoint(large) {
			font-size: rem($base-font-size);
		}
		
		&:focus {
			border-color: color(secondary);
			outline: $outline !important;
			outline-offset: 5px;
			padding: 0.8em 1em !important;
			transition: .3s;
			border-radius: .1rem;
		}
		&:-webkit-autofill {
			padding: 0.8em 1em !important;
		}

		html.contrast-intensify & {
			border: $base-border;
			border-radius: .1rem;
			transition: .3s;
			outline-color: color(alert);
			padding: 0.8em 1em !important;
		}
	}

	textarea {
		/* fake line-height */
		min-height: 3.5rem;
		padding-top: 0.9rem;

		$inputgroup-count: 3;

		&[name="Nachricht"] {
			min-height: 3.5rem * $inputgroup-count - 4rem;
		}
	}

	[type="checkbox"] {
		display: inline-block;
		margin: 0.15rem rem(15px) 0 0;
		padding: 0;
		width: rem(15px);
		height: rem(15px);
		flex-shrink: 0;
		border-radius: .1rem;

		body.iexplore & {
			border: none;

			&:focus {
				background: none;
			}
		}
	}

	[type="radio"] {
		margin: 0 rem(15px) 0 0;
        width: rem(15px);
		min-height: auto;
		flex-shrink: 0;
	}

	button,
	[type="submit"] {
		@extend .btn;
		margin: 0 0 1.75rem;
		width: 100%;

		&:after {
			content: '•••••';
			text-indent: 0;
			display: block;
			color: color(silk);
			line-height: 0;
			position: absolute;
			inset: 50% 0 0;
			opacity: 0;
			visibility: hidden;
		}

		&.show-load {
			pointer-events: none;
			color: color(silk, 0) !important;
			white-space: nowrap;
			position: relative;
			overflow: hidden;
			transition: .5s;

			&:after {
				opacity: 1;
				visibility: visible;
				transition: .3s .5s;
				animation: showLoad .6s ease-out infinite alternate;
			}

			html.contrast-intensify & {
				color: color(silk, 0) !important;
				background: color(medium) !important;
			}
		}
    }

	@keyframes showLoad {
		0% {
			letter-spacing: .1em;
			transform: translateX(0);
		}
		100% {
			letter-spacing: 1em;
			transform: translateX(.4em);
		}
	}
	
    div {
        &.checkbox, 
		&.radio {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                @extend .marginBottom;
            }
            
            label {
                padding-top:0;
                padding-bottom:0;

                &:not(:last-of-type) {
                    margin-right: 2rem;
                }
            }
        }
		&.checkboxesbox,
		&.radiosbox {
			label {
				&.sublabel {
					margin-bottom: 0;
					display: flex;
					flex-wrap: wrap;
				}
			}
		}
    }
}

fieldset.forForm {
	border: none;

	textarea,
	.input,
	input {
		background: color(medium, .2) !important;
		padding: 0.8em 1em !important;
		border: none !important;
		pointer-events: none !important;
		user-select: none !important;
		border-color: color(secondary) !important;
		border-radius: 0.3em;
	}
	textarea {
		min-height: 0;
	}

	// .flex {
	// 	@include breakpoint(small) {
	// 		flex-wrap: nowrap;
	// 		margin-bottom: 0.5rem;

	// 		input {
	// 			margin: 0;
	// 		}
	// 		label {
	// 			width: 30%;
	// 			border: 2px solid #e5ebef;
	// 			border-right: none;
	// 			padding: 0.8em 2em;
	// 			margin-right: -1.5em;
	// 			flex-shrink: 0;
	// 			border-radius: 1.8rem 0 0 1.8rem;
	// 		}
	// 	}
	// }
}

form#Schulen {
	.label-input-container {
		display: flex;
		flex-flow: row wrap;
		gap: 1rem;
	}
	.label-input {
		width: 100%;
	}
	.label-input.schule {
		width: 60%;
		flex-grow: 1;

		@include breakpoint(small, max) {
			margin: 0;
		}
		@include breakpoint(full, max) {
			@include breakpoint(large) {
				margin: 0;
			}
		}

		&.has-error {
			+ .label-input.keine-schule {
				transition: .3s;
				
				@include breakpoint(small) {
					@include breakpoint(large, max) {
						padding-bottom: rem(30px);
					}
				}
				@include breakpoint(full) {
					padding-bottom: rem(30px);
				}
			}
			&:has([name]:valid) {
				+ .label-input.keine-schule {
					padding: 0 !important;
				}
			}
		}
	}
	.label-input.keine-schule {
		align-items: flex-end;
		
		@include breakpoint(small) {
			@include breakpoint(large, max) {
				width: auto;
			}
		}
		@include breakpoint(full) {
			width: auto;
		}

		label:first-child {
			display: none;
		}
	}
	
}

form#Feedback-TdoT {
	label small {
		display: none;
	}
	.moegliche-berufswahl-weil-hellip {
		label {
			display: none;
		}
	}

	fieldset:first-child .label-input-container {
		.radiosbox {			
			> label {
				padding-bottom: .9rem;
				
				&:not(:first-child) {
					flex-grow: 1; 
					width: 20%;
					font-size: 0;
					padding: clamp(2rem, 8vw, 4rem) .66rem clamp(.4rem, 4vw, .66rem);
					justify-content: center;

					input {
						display: none;
					}
					&::before {
						content: '';
						display: block;
						border-radius: 50%;
						width: 1rem;
						height: 1rem;
						background: radial-gradient(at center, #fff 40%, rgba(255,255,255, 0) 40%);
					}
					&.focus {
						&::before {
							background: url('/wp-content/themes/euroweb2021/assets/images/feedback/cross.svg') no-repeat center / contain, radial-gradient(at center, #fff 40%, rgba(255,255,255, 0) 40%);
						}
					}
				}
				&:nth-child(2) {
					background: #FA5A63 url('/wp-content/themes/euroweb2021/assets/images/feedback/very-bad.svg') no-repeat center 30% / 45% auto;
					border-radius: 9rem 0 0 9rem;
				}
				&:nth-child(3) {
					background: #FB833C url('/wp-content/themes/euroweb2021/assets/images/feedback/bad.svg') no-repeat center 30% / 45% auto;
				}
				&:nth-child(4) {
					background: #FCCF3E url('/wp-content/themes/euroweb2021/assets/images/feedback/neutral.svg') no-repeat center 30% / 45% auto;
				}
				&:nth-child(5) {
					background: #B4D77A url('/wp-content/themes/euroweb2021/assets/images/feedback/good.svg') no-repeat center 30% / 45% auto;
				}
				&:nth-child(6) {
					background: #50CA6C url('/wp-content/themes/euroweb2021/assets/images/feedback/very-good.svg') no-repeat center 30% / 45% auto;
					border-radius: 0 9rem 9rem 0;
				}
			}
		}
	}
} 

/*=VALIDATION */

.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: color(alert);
	color: color(silk);
	font-weight: bold;
	margin-bottom: $base-line-height;
	padding: $base-gap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}