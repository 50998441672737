* {
	margin: 0;
	padding: 0;

	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $base-font-size and $base-line-height in config
html {
	background: color(light);
	box-sizing: border-box;
	color: color(dark);
	font-family: $main-font;
	// font-size: 100% * ($base-font-size / 16px);
	font-size: calc(var(--percent-font-size) * #{$base-font-size / 16px});
	hyphens: manual;
	line-height: rem($base-line-height);
	scroll-behavior: smooth;
	overflow-anchor: none;

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;

	@include breakpoint($break) {
		scroll-padding-top: rem(130px);
	}
}

body {
	line-height: rem($base-line-height);
	opacity: 1 !important;
	
	&:not(.javascript) * {
		transition: none !important;
	}
}

iframe,
[data-iframe] {
	border: none;
	width: 100%;
}

video,
iframe[src*="youtube"] {
	aspect-ratio: 16 / 9;
	width: 100%;
	height: auto;
}

html {
	header, main, footer {
		figure {
			margin: 0;
		}
	}
}

address {
	font-style:normal;
}

/**
 * Headlines
 */

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
	margin-bottom: 0.7em;
	display: block;
	text-rendering: optimizeLegibility;
	font-family: $display-font;
	line-height: 1.45em;
	hyphens: auto;
	font-weight: 500;
	color: color(headline);

	@include breakpoint(small) {
		hyphens: manual;
	}
}

h1,h2,h3,
.h1,.h2,.h3 {
	font-weight: 700;
	
	@include breakpoint(tiny) {
		line-height: 1.3em;
	}
	@include breakpoint(giant) {
		line-height: 1.2em;
	}

	&:not(.collapser):before {
		content: attr(data-headline);
	}
	.subline,
	&.subline,
	&:not(.collapser):before {
		display: block;
		line-height: 1.35em;
		margin: 0.3em 0;
		// color: color(secondary);
		font-size: rem(22px);
		
		html.contrast-intensify & {
			color: color(dark);
		}
	}
}

h1,
.h1 {
	font-size: rem(35px);

	@include breakpoint(tiny) {
		font-size: rem(40px);
	}
	@include breakpoint(medium) {
		font-size: rem(50px);
		line-height: 1.08333em;
	}
	@include breakpoint(giant) {
		font-size: rem($h1-size);
	}
}

h2,
.h2 {
	font-size: rem(24px);

	@include breakpoint(tiny) {
		font-size: rem(28px);
	}
	@include breakpoint(medium) {
		font-size: rem($h2-size);
	}
}

h3,
.h3 {
	font-size: rem(22px);

	@include breakpoint(tiny) {
		font-size: rem(26px);
	}
	@include breakpoint(medium) {
		font-size: rem($h3-size);
	}
}

h4,
.h4 {
	font-size: rem(20px);
	font-weight: 700;

	@include breakpoint(tiny) {
		font-size: rem(24px);
	}
	@include breakpoint(medium) {
		font-size: rem($h4-size);
	}
}

h5,
.h5 {
	font-size: rem(18px);

	@include breakpoint(tiny) {
		font-size: rem(22px);
	}
	@include breakpoint(medium) {
		font-size: rem($h5-size);
	}
}

h6,
.h6 {
	font-size: rem(18px);

	@include breakpoint(tiny) {
		font-size: rem(20px);
	}
	@include breakpoint(medium) {
		font-size: rem($h6-size);
	}
}

/**
 * Links
 */
a {
	color: color(headline);
	word-wrap: break-word;
	text-decoration: none;
	
	&.lineLink {
		position: relative;
		display: inline-block;
		padding: 0.1em 0;
		font-weight: 500;
		
		html:not(.contrast-intensify) & {
			&:after {
				content: '';
				width: 100%;
				bottom: 0;
				right: 0;
				position: absolute;
				height: .1rem;
				background: color(headline);
				transition: 0.3s;
			}
			&:hover, &:focus, &:active {
				&:after {
					width: 0;
				}
			}
		}
	}
	html.font-size-110 &.email,
	html.font-size-130 &.email {
		word-break: break-word;
	}

	img {
		border: none;
	}

	&[href^="tel"] {
		color: inherit;
		text-decoration: underline;
	}
}

button:focus-visible,
a:focus-visible,
[tabindex]:focus-visible {
	outline: $outline;
	outline-offset: rem(5px);
}
button:focus-visible,
a:not(.btn):focus-visible,
[tabindex]:focus-visible {
	border-radius: .1rem;
}


mark {
	font-size: .6rem;
	line-height: 1;
	letter-spacing: .04em;
	font-weight: 700;
	background: color(soft);
	color: color(dark);
	border-radius: .2em;
	padding: .5em .6em;
	display: inline-block;
	margin: 0 .2em .2em 0;

	&.icon  {
		&:before {
			margin-right: .3em;
			font-weight: 400;
		}
	}

	html.contrast-intensify & {
		background: color(light);
		color: color(dark);
		border: $base-border;
		border-color: color(noir);
	}
}

hr,
.hr {
	display: flex;
	background: color(middle);
	border: none;
	clear: both;
	height: rem(1px);
	margin: 1rem 0;
	flex-basis: 100%;
}

ul,
ol {
	list-style: none;

	&.styledList {
		li {
			padding: 0.5em 0;
			padding-left: 1.6em;

			&:before {
				@extend .icon;
				@extend .icon-right;
				display: inline-block;
				color: color(headline);
				text-indent: -1.6em;
				vertical-align: -0.15em;
			}
		}

		ul {
			margin-left: 1rem;
			margin-bottom: 0;
		}
	}
}
ol {
	&.styledList {
		counter-reset: item;
	
		> li {
			counter-increment: item;
	
			&:before {
				content: counter(item) ". ";
				font-family: $display-font;
				vertical-align: text-bottom;
				font-weight: 700;
				line-height: 1.3;
			}
		}
		ol {
			counter-reset: item;
			margin-bottom: 0;
	
			> li {
				&:before {
					margin-right: rem(5px);
				}
			}
		}
	}
}

dl {
	&.styledList {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			background: color(middle, .8);
			padding: rem(15px);
		}

		dt {
			padding-bottom: 0;
			font-weight: bold;
		}

		dd {
			padding-top: 0;

			+ dt {
				margin-top: rem(10px);
			}

			+ dd {
				margin-top: rem(-15px);
			}
		}
	}

	&.floatList {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			vertical-align: top;
			float: left;
		}

		dt {
			padding-right: 0.5rem;
			width: 40%;
			clear: left;
		}

		dd {
			width: 60%;
		}
	}

	&.inlineList {
		dt, dd {
			display: inline-block;
			padding-right: 0.2em;
		}
	}
}

/*
 * responsive images
 */

img {
	vertical-align: bottom;
	float: none;
	height: auto;
	max-width: 100%;
	width: 100%;

	&[src^='http://cms.'], &[src^='https://cms.'] {
		max-width: none;
		width: auto;
	}

	[data-rel] &,
	.lightbox-image & {
		margin-bottom: 0;
	}

	&[data-src] {
		@extend %lazyloading;
		transition: opacity 1s;
		
		&:not(.lazyLoaded) {
			opacity: 0;
		}
	}
	.gallery  & {
		border: none !important;
	}
}
// img, video {
// 	html.contrast-intensify & {
// 		filter: grayscale(100%) contrast(125%);
// 	}
// }

/**
 * Tables
 */
main {
	table {
		display: table;
		border-collapse: collapse;
		width: 100%;
		max-width: 100%;
		text-align: left;
		font-size: rem($base-font-size);

		@include breakpoint(small, max) {
			overflow-x: auto;
			display: block;
		}

		caption {
			text-align: left;
			padding: rem($base-gap) rem($base-gap) rem(25px);
			display: table-caption;
			font-weight: bold;
			font-size: 1.2rem;
		}

		thead {
			border-collapse: collapse;
			display: table-header-group;
		}

		tbody {
			width: 100%;
			overflow-x: scroll;
			display: table-row-group;

			tr {
				padding: rem($base-gap) 0;

				&:not(:last-child) {
					border-bottom: rem(1px) solid color(middle);
				}
			}
		}

		tr {
			display: table-row;
		}

		th,
		td {
			padding: rem($base-gap);
			display: table-cell;
			vertical-align: top;
		}

		@include breakpoint(small, max) {
			td {
				min-width: 50vw;
			}
		}
	}
}