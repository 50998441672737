#staff,
#trainee,
#careerTeam {
    position: relative;
	background: color(primary-soft);
	z-index: 1;
    overflow: hidden;

	&:before,
	&:after {
		content: '';
		position: absolute;
		bottom: 25%;
		left: 50%;
		transform: translate(-50%, 50%);
		width: 60vw;
		height: 60vw;
		background: linear-gradient(to bottom, color-lightness(moss, 3%), color(moss, 0) 50%);
		border-radius: 50%;
		z-index: -2;

        html.contrast-intensify & {
            background: linear-gradient(to bottom, color(moss, .05), color(moss, 0) 50%);
        }
	}
    &:after {
        background: linear-gradient(to bottom, color-lightness(moss, 3%), color(moss, 0) 25%);
        width: 110vw;
		height: 110vw;

        html.contrast-intensify & {
            background: linear-gradient(to bottom, color(moss, .05), color(moss, 0) 25%);
        }
    }
    @include breakpoint(cut) {
        &:before,
        &:after {
            width: rem(800px);
            height: rem(800px);
        }
        &:after {
            width: rem(1300px);
            height: rem(1300px);
        }
    }

    .swiper-container {
        &.swiper-started {
            .swiper-slide {
                transition: 20s linear;
            }
        }
    }
}
#staff,
#careerTeam {
    > .row {
        @include space(230px);
    }

    h1 {
        @include breakpoint(tiny, max) {
            font-size: rem(27.5px);
        }
        @include breakpoint(tiny) {
            padding-left: 0.8em;
        }
    }

    blockquote {
        display: flex;
        align-items: center;
        align-content: center;
        padding: rem(20px) rem($base-gap) rem(60px);
        box-sizing: border-box;

        @include breakpoint(huge, max) {
            flex-wrap: wrap;
        }
        @include breakpoint(medium) {
            padding: rem(60px) rem($base-gap);
        }

        &.swiper-slide {
            opacity: 0;
            opacity: hidden;
            flex-shrink: 0;
            width: 100%;
            transform: translateY(10%);

            &-active {
                transform: translateY(-10%);
            }
            
            @include breakpoint(medium) {
                transform: translateY(15%);

                &-active {
                    transform: translateY(-15%);
                }
            }
            @include breakpoint(giant) {
                transform: translateY(25%);

                &-active {
                    transform: translateY(-25%);
                }
            }
            @include breakpoint(huge) {
                transform: translateY(-50%);
                width: 33%;
                
                &-active {
                    transform: translateY(-50%);
                }
                &-prev {
                    transform: translateY(20%);
                }
                &-next {
                    transform: translateY(0%);
                }
            }
            &-active,
            &-prev,
            &-next {
                opacity: 1;
                opacity: visible;
            }
        }

        figure {
            flex-shrink: 0;
            margin: 0.75rem rem(30px) 0.75rem 0;
        }
        .q p {
            margin-bottom: 1em;

            @include breakpoint(huge) {
                margin-bottom: 2em;
            }
        }
        cite {
            font-weight: 500;
            color: color(dark);
        }
        .q, cite {
            @include breakpoint(medium, max) {
                font-size: rem(16px);
                line-height: 1.45em;
            }
        }
    }
}
.glitterCont {
    position: relative;
	z-index: 1;
    overflow: hidden;  

    .glitter {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        z-index: -1;
    
        i {
            position: absolute; 
            background: color(secondary);
            width: 1em;
            height: 1em;
            border-radius: 50%;
    
            &:nth-child(2n) {
                box-shadow: 0 0 0.3rem color(secondary);
            }
            &:nth-child(3n) {
                background: color(primary);
                box-shadow: 0 0 0.3rem color(primary);
            }
            &:nth-child(5n) {
                background: none;
                border: $base-border;
            }

            html.contrast-intensify & {
                background: color(secondary, .2);
                border-color: color(secondary, .2);

                &:nth-child(2n) {
                    box-shadow: 0 0 0.3rem color(secondary, .2);
                }
                &:nth-child(3n) {
                    box-shadow: 0 0 0.3rem color(primary, .2);
                }
            }
        }
    }
}

#staff {
    .swiper-container {
        overflow: visible;
        width: 100%;

        @include breakpoint(full) {
            padding-top: rem(70px);
        }

        .swiper-wrapper {
            transition-timing-function: linear;
            display: flex;
            width: 100%;
            pointer-events: none;
        }
    }
}

#careerTeam {
    background: linear-gradient(to bottom, color(border, 0), color(border));

    &:before,
	&:after {
		background: linear-gradient(to bottom, color(smooth), color(smooth, 0) 50%);
        bottom: 0;
	}
    &:after {
        background: linear-gradient(to bottom, color(smooth), color(smooth, 0) 25%);
    }

    @include breakpoint(cut) {
        &:before,
	    &:after {
            bottom: 25%;
        }
    }
}

#trainee {
    overflow: hidden;
    z-index: 3;

    &:before {
        top: auto !important;
    }
    @include breakpoint(cut) {
        &:before {
            width: rem(1000px);
            height: rem(1000px);
        }
        &:after {
            width: rem(1500px);
            height: rem(1500px);        
        }
    }
    
    > .row {
        @include space(286px);
        padding-bottom: 0 !important;

        &:after {            
            @include breakpoint(micro) {
                content: '';
                z-index: 1;
                display: block;
                margin-left: -2rem;
                background: no-repeat center bottom / contain;
                position: absolute;
                bottom: 0;
                top: rem(30px);
                left: 0;
                width: 110vw; 
                height: auto;
                background: no-repeat calc(-1.9rem + 15%) bottom / auto 100%;

                html.font-size-130 & {
                    content: none;
                }
            }
            @include breakpoint(tiny) {
                background-position: calc(2.6rem + 15%) bottom;
            }
            @include breakpoint(small) {
                top: rem(40px);
                background-position: calc(8rem + 15%) bottom;
            }
            @include breakpoint(medium) {
                background-position: calc(10rem + 15%) bottom;

                
            }
            @include breakpoint(large) {
                background-position: calc(2rem + 30%) bottom;
                top: rem(40px);

                html.font-size-130 & {
                    content: '';
                }
            }
            @include breakpoint(giant) {
                background-position: calc(-1rem + 30%) bottom;
                top: rem(80px);
            }
            @include breakpoint(cut) {
                height: 100vw;
                max-height: rem(1140px);
                max-width: rem(1253px);
                left: 50%;
                transform: translateX(-55%);
                background-position: center bottom;
                top: auto;
            }

        }
        
        &.lazyLoaded {
            &:after {
                @include breakpoint(micro) {
                    background-image: image-url('trainee/tiny-marvin.png');
                }
                @include breakpoint(tiny) {
                    background-image: image-url('trainee/small-marvin.png');
                }
                @include breakpoint(small) {
                    background-image: image-url('trainee/middle-marvin.png');
                }
                @include breakpoint(medium) {
                    background-image: image-url('trainee/large-marvin.png');
                }
                @include breakpoint(large) {
                    background-image: image-url('trainee/giant-marvin.png');
                }
                @include breakpoint(giant) {
                    background-image: image-url('trainee/marvin.png');
                }
            }
        }

        h1 {
            position: relative;
            z-index: 2;

            @include breakpoint(tiny, max) {
                font-size: rem(30px);

                &:before {
                    font-size: 0.5em;
                }
            }
            @include breakpoint(huge, max) {
                @include breakpoint(medium) {
                    padding-top: 7rem;
                }
                @include breakpoint(large) {
                    padding-top: 5rem;
                }
                @include breakpoint(giant) {
                    padding-top: 9rem;
                }
            }

            &:before {
                @include breakpoint(medium, max) {
                    width: 75%;
                }
                @include breakpoint(tiny, max) {
                    width: 60%;
                }
            }
            span.hiding {
                @include breakpoint(small, max) {
                    display: none;
                }
            }
        }
    }
    #traineeSwiper {
        overflow: visible;
        width: 100%;
        position: relative;
        z-index: 10;
        padding-bottom: rem(20px);
        
        @include breakpoint(tiny) {
            padding-bottom: rem(40px);
        }
        @include breakpoint(medium) {
            padding-bottom: rem(70px);
        }
        @include breakpoint(large) {
            padding-top: rem(170px);
            padding-bottom: rem(30px);
            transform: translateX(7rem);
        }
        @include breakpoint(giant) {
            padding-top: rem(240px);
        }
        @include breakpoint(cut) {
            padding-bottom: rem(40px);
        }

        .swiper-wrapper {
            transition-timing-function: linear;
            align-items: flex-start;
            display: flex;
            width: 100%;
            pointer-events: none;
        }
        .swiper-slide {
            padding: rem(10px);
            height: auto;
            box-sizing: border-box;
            position: relative;

            @include breakpoint(large) {
                opacity: 0;
                visibility: hidden;
                transform: translate(150%, -500%) scale(0);
                transform-origin: left top;

                &.swiper-slide-next {
                    + .swiper-slide {
                        opacity: 0.6;
                        visibility: visible;
                        transform: translate(-125%, -200%) scale(0.5);
                    }
                    
                    transform: translate(0%, -100%) scale(1);

                    &, + .swiper-slide {
                        @include breakpoint(cut) {
                            opacity: 0;
                            visibility: hidden;
                        }
                    }
                }
                &.swiper-slide-active {
                    transform: translate(0%, 0%) scale(1);
                }
                &.swiper-slide-prev {
                    opacity: 1;
                    visibility: visible;
                    transform: translate(0%, -50%) scale(0.8);
                }
            }
            @include breakpoint(cut) {
                &.swiper-slide-next {
                    + .swiper-slide {
                        + .swiper-slide {
                            transform: translate(-200%, -500%) scale(0);
                        }
                    }
                }
            }
            &.swiper-slide-next,
            &.swiper-slide-active {
                opacity: 1;
                visibility: visible;

                .box {
                    background: color(headline, .9);
                    color: color(light);

                    // cite {
                    //     color: color(silk);
                    // }
                }
            }
        }
        .box {
            background: color(moss, .9);
            color: color(dark);
            border-radius: 0 0 rem(35px) 0;
            padding: rem(80px) 1.3rem 1rem;
            
            @include breakpoint(large) {
                padding: rem(40px) rem(30px);
                padding-left: rem(90px);
            }
            @include breakpoint(giant) {
                padding: rem(68px) rem(50px);
                padding-left: rem(90px);
            }

            html.contrast-intensify & {
                background: color(light) !important;
                color: color(dark) !important;
                border: $base-border;
            }
        }
        cite {
            display: block;
            padding-top: 1em;
            // color: color(dark);
            line-height: 1.3em;
        }
        cite,
        .highlight {
            @include breakpoint(giant, max) {
                font-size: rem(14px);
                hyphens: auto;
                
                @include breakpoint(large) {
                    font-size: rem(16px);
                }
            }
        }
        .img {
            display: table;
            margin-bottom: rem(-60px);
            z-index: 1;

            @include breakpoint(large) {
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(-50%, -20%);
            }
            @include breakpoint(cut) {
                transform: translate(-50%, -50%);
            }
        }

        &.swiper-started {
            .swiper-slide {
                transition: transform 20s linear, opacity 2s linear, visibility 2s linear;

                .box {
                    transition: background 1s 13s linear, color 2s 13s linear;
                }
                &.swiper-slide-next {
                    @include breakpoint(cut) {
                        transition: transform 20s linear, opacity 13s 7s linear, visibility 13s 7s linear;
                    }

                    .box {
                        transition: background 1s 18s linear, color 2s 18s linear;
                    }

                    + .swiper-slide {
                        @include breakpoint(cut, max) {
                            transition: transform 1s linear, opacity 2s 18s linear, visibility 2s 18s linear;
                        }
                    }
                }
            }
        }
    }
}


// Total numner of snowflakes
$glitters: 30;

// Randomize the animation and positioning for each snowflake
@for $i from 1 through $glitters {
  // Position of the snowflake on the y-axis
  $top: (random(50) + 20) * 1%;

  // Position of the snowflake on the x-axis
  $left: random(100) * 1%;

  // Animation delay for the flake
  $delay: random(20) - 1s;

  // Floating span for the flake
  $duration: (random(20) + 10) + 10s;

  // Size of the flake
  $size: random(5);

   /* Snowflake ##{$i} */
  .glitter i:nth-of-type(#{$i}) {
    animation-name: glitter-#{$i};
    animation-delay: $delay;

    // Play the animation for anything between 5-10 seconds
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    left: $left;
    top: $top;
    font-size: $size * 0.1rem;
  }
  
  // Animation for glitter ##{$i}
    @keyframes glitter-#{$i} {
        0% {
            transform: scale(0.2);
            left: $left;
            top: $top;
        }
        25% {
            left: $left + 1%;
            top: $top + 3%;
            opacity: 1;
        }

        50% {
            top: $top - 1%;
            left: $left;
            opacity: 0.5;
        }

        75% {
            left: $left + 2%;
            top: $top - 5%;
            opacity: 1;
        }
        100% {
            transform: scale(1.5);
            opacity: 0;
        }
    }
}