/** create css property **/
@function config($variable) {
    @return var(--#{$variable});
}

/** return hsla value for colors **/
@function color($variable, $alpha: 1) {
    @if $alpha != 1 and $alpha > 0 {
        // return HSLA color sheme with alpha channel
        $colorVar: hsla(#{var(--#{$variable}-h)}, #{var(--#{$variable}-s)}, #{var(--#{$variable}-l)}, #{$alpha});

        $integer: split-str(#{$alpha}, ".");
        $value: str-length(nth($integer, 2));

        @if $value != 1 {
            $value: nth($integer, 2);
        } @else {
            $value: unquote(nth($integer, 2) + "0");
        }

        @return var(--#{$variable}-opacity-#{$value}, $colorVar);

    } @else if $alpha == 0 {
        $colorVar: hsla(#{var(--#{$variable}-h)}, #{var(--#{$variable}-s)}, #{var(--#{$variable}-l)}, #{$alpha});
        @return var(--#{$variable}-opacity-0, $colorVar);
        
    } @else {
        @return config($variable);
    }
}

/**
* return decimal number from alpha value
* if value equal 0.7 -> insert 70
* if value equal .75 -> insert 75
*/
@function split-str($string, $separator) {

    $index : str-index($string,  $separator);
 
    $str-1 : str-slice($string, 1, $index - 1);
    $str-2 : str-slice($string, $index + 1);
 
    @return $str-1 $str-2;
 }

/**
* return an calculated new lightness value for hsla
*/
@function new-color-value($color, $percent) {
    $minus: str-slice($percent, 1, 1) == '-';
    
    $operator: "+";
    @if $minus {
        $operator: "-";
        $percent: str-slice($percent, (str-length($percent) - 1) * -1, -1);
    }
    $sum: #{$color} $operator unquote($percent);
    @return calc(#{$sum});
}

/**
* return hsla value
*/
@function color-lightness($variable, $percent: 0%, $alpha: 1) {
    @return hsla(
        #{var(--#{$variable}-h)},
        #{var(--#{$variable}-s)},
        new-color-value(#{var(--#{$variable}-l)}, #{$percent}),
        $alpha
    );
}

/**
* create css custom properties for hsla color
*/
@mixin define-color($name, $color) {
    --#{$name}: hsla(
        var(--#{$name}-h), 
        var(--#{$name}-s), 
        var(--#{$name}-l), 
        var(--#{$name}-a)
    );

    --#{$name}-h: #{hue($color)/1deg};
    --#{$name}-s: #{saturation($color)};
    --#{$name}-l: #{lightness($color)};
    --#{$name}-a: #{alpha($color)};
}

/**
* returns a matching foreground color for given color
*/
@function get-matching-foreground-color($color) {
    $foreground: color(silk);

    @if (lightness($color) > 60%) {
        $foreground: color(dark);
    }

    @return $foreground;
}
