/*
* Mixin for Responsive Spaces
* @author Jasmin Reiff
* @version 1.2
* @category scss mixin
*/

@mixin space($space)
{
	padding-top: #{rem($base-line-height * 3)};
	padding-bottom: #{rem($base-line-height * 2)};
	
	@if ($space < ($base-line-height * 4))
	{
		@include breakpoint(large) 
		{
			padding-top: rem($space);
			padding-bottom: rem($space - $base-line-height);
		}
	}
	@else 
	{
		@include breakpoint(large)
		{
			padding-top: #{rem($base-line-height * 4)};
			padding-bottom: #{rem($base-line-height * 3)};
		}

		@if ($space < ($base-line-height * 5))
		{
			@include breakpoint(giant) 
			{
				padding-top: rem($space);
				padding-bottom: rem($space - $base-line-height);
			}
		}
		@else 
		{
			@include breakpoint(giant)
			{
				padding-top: #{rem($base-line-height * 5)};
				padding-bottom: #{rem($base-line-height * 4)};
			}
			@include breakpoint(huge) 
			{
				padding-top: rem($space);
				padding-bottom: rem($space - $base-line-height);
			}		
		}
	}
}


@keyframes wiggle {
	from {
		transform: translate3d(0, -1%, 0) rotate(0.1deg);
	}
	to {
		transform: translate3d(0, 1%, 0) rotate(-0.1deg);
	}
}
// will-transform