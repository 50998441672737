/**
* mixin to include icon basic styles or a specific icon
*/
@mixin icon($name: "") {
	@if($name) {
		content: map-get($icon-map, $name);
	}

	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	font-family: $icon-font;
}

%icon:before,
.icon:before {
	@include icon;
}

@each $name, $ico in $icon-map {
	.icon-#{$name}:before {
		content: "#{$ico}";
	}
}

.icon-play:before {
  text-indent: 0.2em;
}