#blogArticles {
    background: color(border);
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 100%;

    &:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(50%, -50%);
		width: 38vw;
		height: 38vw;
		background: color-lightness(soft, -2.5%);
		border-radius: 50%;
		z-index: -1;
		box-shadow:
            0 0 0 19vw color-lightness(soft, -.5%),
            0 0 0 38vw color-lightness(soft, 1.5%);

        @include breakpoint(cut) {
            right: 50%;
			transform: translate(rem($row-max-width / 2), -50%);
            width: rem(630px);
            height: rem(630px);
            box-shadow:
                0 0 0 rem(315px) color-lightness(soft, -.5%),
                0 0 0 rem(630px) color-lightness(soft, 1.5%);
        }
	}

    > .setSpace {
        @include space(250px);
    }

    h1:not(.h4) {
        margin-bottom: 0;
        
        @include breakpoint(giant) {            
            @include breakpoint(huge, max) {
                font-size: rem(56px);
            }
        }

        body:not(.post-type-archive-karriere) & {
            @include breakpoint(full) {
                margin-bottom: rem(80px);
            }
        }
        
        br {
            @include breakpoint(small) {
                display: none; 
            }
            @include breakpoint(full) {
                @include breakpoint(end, max) {
                    display: block; 
                }
            }
        }
    }

    .has-swiper {
        overflow: hidden;

        @include breakpoint(cut) {
            padding-left: calc((100vw - #{rem($row-max-width)}) / 2);
        }
    }
    
    .swiper-container {
        overflow: visible;
        width: 100%;
        padding-left: rem($base-gap);
        padding-right: 8.33333%;

        .swiper-wrapper {
            display: flex;
            width: 100%;
            transition-timing-function: ease-in-out;
        }
        .swiper-slide {
            padding-right: rem($base-gap);
            flex-shrink: 0;
            width: 33%;
            box-sizing: border-box;
            
            @include breakpoint(cut) {
                padding-right: rem(30px);
            }
        }
    }
    .paginationContainer {
        @include breakpoint(cut) {
            padding-bottom: rem(80px);
            margin-bottom: rem($base-line-height);
        }
    }
    .swiper-pagination-blog {
        display: flex;
        width: 100%;
        align-items: center;
        font-size: rem(12px);
        font-weight: 700;
        line-height: 1;
        color: color(dark);

        @include breakpoint(large, max) {
            display: none;
        }

        &, span.total {
            &:before {
                content: '';
                border-bottom: 2px solid color(dark);
                width: 1px;
                flex-grow: 1;
                margin-right: rem($base-gap);
                display: inline-block;
                
                @include breakpoint(huge) {
                    margin-right: rem($base-gap * 2);
                }
            }
        }

        span {
            display: inline-block;

            &.total {
                color: color(dark);

                &:before {
                    width: 1em;
                    border-color: color(dark);
                    margin: 0 0.7em;
                    vertical-align: 0.33em;

                    @include breakpoint(large) {
                        width: 2em;
                    }
                }
            }
        }
    }
    .category_list {
        display: flex;
        width: 100%;
        gap: rem(3px);
        margin-bottom: rem(4px);
        padding-top: rem($base-line-height);
        padding-bottom: rem($base-line-height);
        
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
        
        @include breakpoint(small) {
            gap: rem(10px);
        }
        @include breakpoint(large) {
            overflow: visible;
            padding-top: rem($base-line-height * 2);
            padding-bottom: rem($base-line-height * 2);
        }
        @include breakpoint(full) {
            padding-bottom: rem(80px);

            body:not(.post-type-archive-karriere) & {
                padding-top: rem(80px);
            }
        }
        @include breakpoint(cut) {
            gap: rem(10px);
            margin-bottom: rem($base-line-height);
            padding-bottom: 2.8rem;
        }        

        > * {
            scroll-snap-align: center;
        }
    }
}
.article-list,
.single-blog-header {
    time {
        position: absolute;
        top: 0;
        right: 0;
        background: color(secondary);
        border-radius: 0 0 0 1.5rem;
        letter-spacing: 0.04em;
        font-weight: 700;
        font-size: rem(10px);
        text-transform: uppercase;
        padding: 0.9em 2.35em;
        
        @include breakpoint(large) {
            font-size: rem(12px);
        }

        html.contrast-intensify & {
            background: color(dark);
        }

        span {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 0.2em;
            }
            &.day {
                font-size: 1.4em;
            }
            // &.year {
                // color: color-lightness(medium, 10%);
            // }
        }
    }
}
.article-list {
    .status-publish {
        height: auto;

        > a {
            @extend .rCrop;
            background: color(secondary);
            position: relative;
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            transition: 0.3s;

            * {
                color: color(light);
            }

            html.contrast-intensify & {
                background: color(action);

                &:hover,
                &:focus {
                    background: color(hover);
                }
                
                &,
                * {
                    color: color(action-color) !important;
                }
            }

            &:hover, &:focus, &:active {
                background: color(hover);
            }
        }
        img {
            height: rem(150px);
            object-fit: cover;

            @include breakpoint(small, max) {
                object-position: center top;
            }
            
            @include breakpoint(giant) {
                height: rem(210px);
            }
            @include breakpoint(huge) {
                height: rem(255px);
            }
            @include breakpoint(full) {
                height: rem(307px);
            }
        }
        .post-header {
            padding: rem($base-gap) rem($base-gap * 1.5); 
            flex-grow: 1;
            display: flex;
            flex-flow: column nowrap;
            
            @include breakpoint(tiny) {
                padding: rem($base-gap * 1.5) rem($base-gap * 2); 
            }
            @include breakpoint(small) {
                padding: rem($base-gap) rem($base-gap * 1.5); 
            }
            @include breakpoint(medium) {
                padding: rem($base-gap * 1.5) rem($base-gap * 2); 
            }
            @include breakpoint(giant) {
                padding: rem($base-gap * 2) rem($base-gap * 3) rem($base-gap * 2.5) rem($base-gap * 4); 
            }
            @include breakpoint(huge) {
                padding: rem($base-gap * 3) rem($base-gap * 3) rem($base-gap * 3.5) rem($base-gap * 4); 
            }
            @include breakpoint(full) {
                padding: rem(68px) rem(50px) rem(50px) rem(68px); 
            }
            
            .h4 {
                hyphens: auto;
                flex-grow: 1;
                font-size: clamp(rem($base-font-size), 2vw, rem($h4-size));
            }
            &:after {
                content: map-get($icon-map, arrow-right);
                font-family: $icon-font;
                color: color(medium);
                display: block;
                text-align: right;

                html.contrast-intensify & {
                    color: color(light);
                    float: right;
                }
            }
        }
    }
}

//Singles
/////////////////////

body.single-blog #content {
    background: linear-gradient(to bottom, color(silk, 0.05), color(silk) 45vh);
    
    @include breakpoint(cut) {
        background: linear-gradient(to bottom, color(silk, 0.05), color(silk) rem(1020px));
    }
}
.single-blog-header {
    background: color(secondary);
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    
    &, h1 {
        color: color(silk);
    }
    img {
        object-fit: cover;
        height: rem(220px);
        order: 1;
        
        @include breakpoint(small) {
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;
            
        }

        + .txt {
            @include breakpoint(small) {
                // margin-left: auto;
                width: 50%;
            }
        }
    }
    .txt {
        padding: rem($base-gap * 1.5) rem($base-gap);


        @include breakpoint(small, max) {
            padding-top: 4rem;
        }

        @include breakpoint(tiny) {
            padding: rem($base-gap * 2);
        }
        @include breakpoint(large) {
            padding: rem($base-gap * 3);
        }
        @include breakpoint(giant) {
            padding: rem($base-gap * 4);
        }

        h1 {
            hyphens: auto;

            @include breakpoint(large, max) {
                font-size: clamp(rem(26px), 5vw, rem(38px));
            }
        }
    }

    .cats {
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        margin: 0 rem($base-gap / -2);

        .btn {
            font-size: rem(12px);
            margin: rem($base-gap / 2);
            background: none;
            color: color(medium);
            box-shadow: none;
            border: 3px solid color(medium);
            padding: 0.6em 1.2em;

            &:hover, &:active, &:focus {
                border-color: color(primary);
                color: color(primary);
                background: none;
            }
        }
    }
}