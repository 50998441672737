#references {
    &-list {
        li {
            animation: wiggle 5s ease-in-out infinite alternate;
            margin-bottom: rem($base-gap * 2);
            
            @include breakpoint(full) {
                margin-bottom: calc(#{rem($base-gap * 2)} + 2vw);
            }
            @include breakpoint(cut) {
                margin-bottom: 6.7rem;
            }

            figure {
                box-shadow: 0 0 rem(40px) color(dark, .2);
                display: inline-block;
                width: 100%;
                max-width: rem(460px);

                img {
                    width: 100% !important;
                }
            }
            
            &:nth-child(6n + 1) {
                animation-duration: 4s;
            }
            &:nth-child(6n + 2) {
                animation-duration: 4.5s;
            }
            &:nth-child(6n + 3) {
                animation-duration: 3.5s;
            }
            &:nth-child(6n + 4) {
                animation-duration: 5s;
            }
            &:nth-child(6n + 5) {
                animation-duration: 4.2s;
            }
            &:nth-child(6n + 6) {
                animation-duration: 4.8s;
            }

            &:nth-child(3n + 2) {
                text-align: center;

                figure {
                    @include breakpoint(medium) {
                        transform: translateY(rem(22px));
                    }
                    @include breakpoint(giant) {
                        transform: translateY(rem(43px));
                    }
                    @include breakpoint(full) {
                        transform: translateY(rem(111px));
                    }
                }
                
                // @include breakpoint(tiny) {
                //     padding-top: rem($base-line-height * 3);
                // }
            }
            &:nth-child(3n + 3) {
                text-align: right;

                figure {
                    @include breakpoint(medium) {
                        transform: translateY(rem(10px));
                    }
                    @include breakpoint(giant) {
                        transform: translateY(rem(25px));
                    }
                    @include breakpoint(full) {
                        transform: translateY(rem(40px));
                    }
                }
            }
        }
        
    }
    &-teaser {
        @include space(180px);
        background: linear-gradient(to bottom, color(border) 60%, color(soft) 90%);
        position: relative;
        z-index: 4;
        
        html.contrast-intensify & {
            background: linear-gradient(to bottom, color(border, .8) 60%, color(border) 90%);
        }

        h1 {
            hyphens: manual;

            br {
                @include breakpoint(medium, max) {
                    display: none;
                }
            }
        }

        .highlight,
        .highlightBig {
            letter-spacing: 0;
        }

        #references-list {
            li {
                figure {
                    transform: none !important;
                }

                &:nth-child(3n + 1) {
                    padding-top: rem($base-line-height);
                }
                &:nth-child(3n + 2) {
                    padding-top: rem($base-line-height * 2);
                    
                    @include breakpoint(tiny) {
                        padding-top: rem($base-line-height * 3);
                    }
                }
    
                @include breakpoint(giant) {
                    &:nth-child(3n + 1) {
                        padding-top: rem(80px);
                    }
                    &:nth-child(3n + 2) {
                        padding-top: rem(160px);
                    }
                }
            }
        }
    }
}