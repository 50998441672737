// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: medium; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$nav-bg: color(silk); // Navigation Background
$nav-color: color(dark); // Navigation Link Color
$desktop-hover: false;

@mixin nav-button {
	// Default Link Styles
	padding: 5vh rem($base-gap);
	text-decoration: none;
	color: $nav-color;
	letter-spacing: 0.04em;
	font-weight: 700;
	font-size: rem(14px);
	line-height: 1.4em;
	color: color(dark);
	transition: 0.5s;
	text-align: center;
	justify-content: center;
	position: relative;
	outline-offset: -5px;
	border-radius: .5rem;

	@include breakpoint(tiny) {
		font-size: rem(18px);
	}
	@include breakpoint($break) {
		padding: .75em .5em;
		font-size: rem(14px);
	}
	@include breakpoint(huge) {
		font-size: rem(16px);
	}
	@include breakpoint(cut) {
		font-size: rem(18px);
	}
}
%button-hover {
	// Link Hover
	&:before {
		transform: translate(-50%, 50%) scale(1);
		
		@include breakpoint($break) {
			transform: translate(-50%, 0%) scale(1);
		}
	}
}
%button-active {
	// Link Active
	&:before {
		transform: translate(-50%, 50%) scale(1);
		
		@include breakpoint($break) {
			transform: translate(-50%, 0%) scale(1);
		}
	}
}
%prev-icon {
	// Icon to previus Submenu
	font-family: $icon-font;
	content: map-get($icon-map, right);
	transform: rotate(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%next-icon {
	// Icon to next Submenu
	font-family: $icon-font;
	content: map-get($icon-map, right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$close-icon: map-get($icon-map, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$quickbar-for-desktop: false;
$bar-burger-menu-lines: 2px;
$bar-height: 55px; // height (in px)
$bar-pos: top; // position (top, bottom)
$bar-hide: true; // if bar hides on scroll (true, false)

$bar-bg: color(silk); // background-color
$bar-color: color(dark); // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include page-navi {
	transition: .5s;
	background: $nav-bg;
	z-index: 1001;
	inset: 0;	
	width: 100%;
	transform: translate(0, 100%);
	visibility: hidden;
	opacity: 0;
	
	// @include breakpoint($break, max) {
	// 	backdrop-filter: blur(8px);
	// }

	// html.contrast-intensify & {
	// 	background: color(light) !important;
	// }

	// body.javascript & {
	// 	opacity: 1 !important;
	// }

	nav {
		display: flex;
        flex-wrap: wrap;
        align-content: center;
        padding-top: 4rem;
		
		@include ul {
			&.navi-main {
				li {
					@include nav-buttons {
						@include nav-button;

						&:before {
							content: '';
							width: 0.8em;
							height: 0.8em;
							background: color(primary);
							transition: 0.4s;
							display: inline-block;
							position: absolute;
							transform: translate(-50%, 50%) scale(0);
							left: 50%;
							bottom: 0;
							border-radius: 50%;
							z-index: -1;
							
							@include breakpoint($break) {
								transform: translate(-50%, 0) scale(0);
								width: rem(10px);
								height: rem(10px);
							}
						}
					}

					a[data-article-count] {
						position: relative;
						white-space: nowrap;

						@include breakpoint($break, max) {
							text-indent: 1.2em;
						}
					
						&:after {
							content: attr(data-article-count);
							display: inline-block;
							background: color(primary);
							color: color(dark);
							font-size: rem(13px);
							font-weight: 700;
							width: 2.15em;
							height: 2.15em;
							line-height: 2.3em;
							border-radius: 50%;
							text-align: center;
							transform: translate(25%, -60%);
							text-indent: 0;
							box-shadow: 0 0 rem(10px) color(dark, .1);

							html.contrast-intensify & {
								background: color(dark);
								color: color(light);
							}

							@include breakpoint($break) {
								font-size: rem(10px);
								transform: translate(12%, -40%);
								position: absolute;
								top: 0;
								right: 0;
								left: auto;
								bottom: auto;
							}
							@include breakpoint(huge) {
								transform: translate(60%, -20%);
								font-size: rem(13px);
							}
						}
					}
					
					@include breakpoint($break, max) {
						padding: 0 rem($base-gap);
						overflow: hidden;
						
						&.current-menu-item, &:hover, &:focus, &:active {
							a,
							&.menu-item-has-children > span {
								&:before {
									transform: translate(-50%, 50%) scale(1);
								}
							}
						}
						&:not(:last-child) {
							border-bottom: $base-border;
							border-color: color(dark);

							html.contrast-intensify & {
								border-width: 2px;
							}
						}
						&:last-child {
							overflow: visible;

							a,
							&.menu-item-has-children > span {
								padding-bottom: 1.4em;
							}
						}
					}
					
					@include has-sub {
						@include sub {
							background: $nav-bg;

							&:before {
								padding: 1em rem($base-gap);
							}
						}
					}
					@include nav-back {
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktop-version-functionality;

	#navigation {
		background: none;
		transform: none;
		left: auto;
		bottom: auto;
		opacity: 1;
		z-index: 1005;
		transition: top .5s !important;

		nav {
			max-width: rem($row-max-width);
			margin: 0 auto;
			padding: 0;
			
			ul {
				&.navi-main {
					padding-left: rem(100px + $base-gap * 2.5);
					padding-right: rem($base-gap * 1.5);
					justify-content: space-around;
					align-items: center;
					transition: height .5s;
					
					@include breakpoint(large) {
						padding-left: rem(150px);
						padding-right: rem(320px);
					}
					@include breakpoint(giant) {
						justify-content: flex-start;
					}
					@include breakpoint(huge) {
						padding-left: rem(280px - $base-gap);
					}
					@include breakpoint(full) {
						padding-left: rem(190px + $base-gap * 3 + 50);
					}

					> li {
						visibility: visible;
						
						@include breakpoint(giant) {
							margin-right: rem($base-gap * 1.75);
						}

						> a, &.menu-item-has-children > span {
							position: relative;
						}
						&.current-menu-item, &:hover, &:focus, &:active {
							a {
								&:before {
									transform: translateX(-50%) scale(1);
								}
							}
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	color: $bar-color;
	z-index: 1002;
	top: 0;
	height: rem(64px);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: rem($base-gap / 2);

	@include breakpoint($break) {
		transition: top .5s !important;
	}
	@include breakpoint(cut) {
		max-width: rem($row-max-width);
		left: 50%;
		transform: translateX(-50%);
	}
	
	.bar-bg {
		background: $bar-bg;
		border-radius: 5rem;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 0.3rem rem($base-gap);
		transition: height .5s, box-shadow .5s;
		margin: 0 auto;
		position: relative;
		height: 100%;
		padding: .3rem rem($base-gap);
			
		@include breakpoint(micro) {
			padding: .3rem rem($base-gap * 1.5);
		}
		@include breakpoint($break) {
			.menu {
				display: none;
			}
		}
		@include breakpoint(large) {
			gap: rem($base-gap);
		}
		@include breakpoint(huge) {
			gap: rem($base-gap * 2);
		}
		@include breakpoint(full) {
			padding-right: rem($base-gap * 3);
		}

		body:not(.home) & {
			border: 1px solid color(flat);
		}
	}

	.socials {
		display: none;

		@include breakpoint(large) {
			display: flex;
			align-items: center;
			height: 100%;
		}
	
		li {
			padding: rem($base-gap);
			
			@include breakpoint(large) {
				padding: rem($base-gap / 4);
			}
			@include breakpoint(huge) {
				padding: rem($base-gap / 2);
			}
	
			a {
				width: rem(20px);
				display: block;
				transition: 0.4s;
				visibility: visible;
	
				img {
					display: block;
				}
	
				&:hover, &:focus, &:active {
					transform: scale(1.2);
				}
			}
		}
	}
	
	.menu {
		flex-shrink: 0;
		padding-left: rem($base-gap / 2);
	}
}

#branding {
	position: fixed;
	top: 0;
	left: 0;
	padding-left: rem($base-gap);
	z-index: 1100;
	display: flex;
	align-items: center;
	height: rem(64px);
	transition: height .5s, padding .5s, top .5s;
	padding-left: rem($base-gap * 1.5);

	@include breakpoint(micro) {
		padding-left: rem($base-gap * 2);
	}
	@include breakpoint(full) {
		padding-left: rem($base-gap * 3);
	}
	@include breakpoint(cut) {
		width: 100%;
		max-width: rem($row-max-width);
		left: 50%;
		transform: translateX(-50%);
		visibility: hidden;
	}

	a {
		// margin-bottom: .1rem;
		visibility: visible;
		transition: height .3s;
		width: rem(150px);
		display: block;

		@include breakpoint($break) {
			width: rem(100px);
			// margin-bottom: rem(10px);
		}
		@include breakpoint(huge) {
			width: rem(190px);
		}

		svg, img {
			display: block;
			width: 100%;
		}
	}
}



div.accessibility-bar {
	position: fixed;
	bottom: rem($base-gap / 2);
	right: rem($base-gap / 2);
	z-index: 300;
	padding: rem($base-gap / 2);
	transition: .5s;
	background: color(action);
	border-radius: 9rem;
	box-shadow: 0 0 1rem color(dark, .2);
	z-index: 2000;

	@include breakpoint(giant) {
		bottom: rem($base-gap * 1.25);
		right: rem($base-gap * 1.25);
	}

	ul.accessibility-bar {
		gap: rem($base-gap / 2);
		flex-direction: column;
	}

	button {
		border: none;
		border-radius: 50% !important;
		transition: .3s;
		
		svg path {
			fill: color(action-color);
		}
		
		&.active {
			background: color(light);
			
			svg path {
				fill: color(dark);
			}
		}
		&:hover,
		&.active:hover {
			background: color(hover);
			
			svg path {
				fill: color(hover-color);
			}
		}
	}

	&:empty {
		display: none;
	}
	
	html.contrast-intensify & {
		border: 1px solid color(light);
	}
	html.font-size-130 & .accessibility-bar__item--font-size {
		position: relative;
		
		&:after {
			content: 'x2';
			position: absolute;
			right: 0;
			bottom: 0;
			font-weight: bold;
			color: color(dark);
			z-index: 1;
			display: block;
			font-size: .6rem;
			padding: .3em;
			line-height: 1;
			background: color(soft);
			border-radius: 9em;
			transform: translate(25%, 25%);
		}
	}
}


@include breakpoint($break) {
	#quickbar,
	#branding,
	#navigation ul.navi-main {
		height: rem(80px);

		@include breakpoint(medium) {
			height: rem(100px);
		}
		@include breakpoint(huge) {
			height: rem(120px);
		}
	}
	#quickbar,
	#branding,
	#navigation {
		@include breakpoint(cut) {
			top: rem(50px);
		}
	}
}


body {
	&.scrolled,
	&.post-type-archive-referenzen.scrolled,
	&.post-type-archive-blog.scrolled,
	#navi-toggled:checked ~ {
		#quickbar {
			.bar-bg {
				border: 1px solid color(dark, .1);
				// box-shadow: 0 0 3rem color(noir, .1);
			}
		}
		#quickbar,
		#branding,
		#navigation {
			@include breakpoint(cut) {
				top: rem($base-gap / 2);
			}
		}
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////

#menu-footer {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	
	@include breakpoint(small) {
		border-top: $base-border;
		padding-top: rem(50px);
		justify-content: space-around;
	}
	@include breakpoint(medium) {
		justify-content: center;
	}
	@include breakpoint(large) {
		justify-content: flex-start;
		
		@include breakpoint(giant, max) {
			padding-bottom: rem($base-line-height);
		}
	}

	li {
		display: inline-block;
		width: 100%;

		@include breakpoint(small) {
			font-size: rem(13px);
			width: auto;
		}
		@include breakpoint(medium) {
			font-size: rem(15px);
			margin-right: 1.6em;
		}
		@include breakpoint(giant) {
			font-size: rem(18px);
		}
		@include breakpoint(huge) {
			margin-right: 2em;
		}

		a {
			position: relative;
			padding: 1em 0.5em;
			letter-spacing: 0.04em;
			color: color(dark);
			display: block;
			font-weight: 700;
			text-align: center;
			overflow: hidden;
			z-index: 1;

			@include breakpoint(medium) {
				padding: 1em 0;
			}

			&:before {
				content: '';
				width: 0.8em;
				height: 0.8em;
				background: color(primary);
				transition: 0.4s;
				display: inline-block;
				position: absolute;
				transform: translate(-50%, 50%) scale(0);
				left: 50%;
				bottom: 0;
				border-radius: 50%;
				z-index: -1;
				
				@include breakpoint(small) {
					transform: translate(-50%, 0) scale(0);
					width: rem(10px);
					height: rem(10px);
				}
			}
		}
		&.current-menu-item, &:hover, &:focus, &:active {
			a {
				&:before {
					transform: translate(-50%, 50%) scale(1);

					@include breakpoint(small) {
						transform: translate(-50%, 0) scale(1);
					}
				}
			}
		}

		@include breakpoint(small, max) {
			&:not(:last-child) {	
				border-bottom: $base-border;
			}
			&:last-child {
				a {
					padding-bottom: 1.4em;
				}
				&.current-menu-item, &:hover, &:focus, &:active {
					a {
						&:before {
							transform: translate(-50%, 0%) scale(1);
						}
					}
				}
			}
		}
	}
}