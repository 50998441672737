/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$image-path: "/wp-content/themes/euroweb2021/assets/images/" !default;

$base-font-size: 18px;

$base-line-height: 30px;

$h1-size: 60px;

$h2-size: 30px;

$h3-size: 28px;

$h4-size: 26px;

$h5-size: 24px;

$h6-size: 22px;

$main-font: "Red Hat Display", sans-serif;

$display-font: "Red Hat Display", sans-serif;

$icon-font: icomoon;

// Project colors

$secondary: #225D93;
$primary: #BAD32C;
$white: #fff;
$black: #000;
$light: #F9FAFB;
$middle: #707070;
$dark: #1B1414;
$border: rgba(#9BA4AD, .3);
$borderFull: #9BA4AD;
$alert: #FEC001;
$lightestBlue: #C2C9D0;
$lightBlue: color(medium);
$middleBlue: #01467F;
$darkBlue: #004676;
$darkestBlue: #003662;
$bgGreen: #F6F9E8;
$bgBlue: color(medium);
$olive: #D3DACF;

$color-map: (
	primary: (
		main: #BAD32C, 
		contrast: #615700, 
	),
	olives: (
		main: #D3DACF, 
		contrast: #d5d5d5, 
	),
	moss: (
		main: #E9ECD7, 
		contrast: #615700, 
	),
	primary-soft: (
		main: #F6F9E8, 
		contrast: #efefef, 
	),
	secondary: (
		main: #225D93, 
		contrast: #615700, 
	),
	headline: (
		main: #003662, 
		contrast: #000, 
	),
	list: (
		main: #738DA7, 
		contrast: #615700, 
	),
	medium: (
		main: #C2C9D0, 
		contrast: #999, 
	),
	middle: (
		main: #707070, 
		contrast: #707070, 
	),
	dark: (
		main: #1B1414, 
		contrast: #000, 
	),
	light: (
		main: #fff, 
		contrast: #fff, 
	),
	silk: (
		main: #F9FAFB, 
		contrast: #fff, 
	),
	smooth: (
		main: #F3F6F8, 
		contrast: #fff, 
	),
	sleek: (
		main: #959da5, 
		contrast: #000, 
	),
	ice: (
		main: #E7EDED, 
		contrast: #fff, 
	),
	border: (
		main: #F7F9F9, 
		contrast: #F5F5F5, 
	),
	border-full: (
		main: #9BA4AD, 
		contrast: #707070, 
	),
	flat: (
		main: #E1E4E7, 
		contrast: #707070, 
	),
	soft: (
		main: #EBEEF1, 
		contrast: #E5E5E5, 
	),
	pale: (
		main: #f4f5f9, 
		contrast: #fff, 
	),
	faint: (
		main: #EEF0F4, 
		contrast: #fff, 
	),
	outrageous: (
		main: #000C15, 
		contrast: #000, 
	),
	mould: (
		main: #C8CBB1, 
		contrast: #000, 
	),
	middle-blue: (
		main: #01467F, 
		contrast: #003CF0, 
	),
	dark-blue: (
		main: #004676, 
		contrast: #003CF0, 
	),
	noir: (
		main: #000, 
		contrast: #000, 
	),
	alert: (
		main: #FEC001, 
		contrast: #8F0040, 
	),
	action: (
		main: #225D93, 
		contrast: #003CF0, 
	),
	action-color: (
		main: #fff, 
		contrast: #fff, 
	),
	hover: (
		main: #01467F, 
		contrast: #000, 
	),
	hover-color: (
		main: #fff, 
		contrast: #fff, 
	),
);

$base-gap: 18px;

$row-max-width: 1666px;

$column-count: 12;

$base-border-radius: 9px;

$base-border: 1px solid var(--flat);

$outline: 3px solid var(--alert);

$bp-context: 16px;

$hide-text-direction: right;

$icon-map: (
	arrow-right: "\e90f", 
	briefcase: "\e9ae", 
	brush: "\f1fc", 
	bubble: "\e903", 
	bulb: "\e912", 
	code: "\e902", 
	compress: "\e906", 
	display: "\e956", 
	down: "\e904", 
	drafting-compass: "\e910", 
	exclamation: "\e901", 
	filter: "\e90a", 
	hand: "\e905", 
	handshake: "\e911", 
	ice: "\e90d", 
	left: "\e914", 
	location-arrow: "\f124", 
	log-in: "\e913", 
	map: "\f279", 
	map-marker: "\e917", 
	map-signs: "\f277", 
	office: "\e91e", 
	pen: "\e90c", 
	play: "\e90e", 
	play-work: "\e907", 
	right: "\e915", 
	search: "\e900", 
	speak: "\e908", 
	steps: "\e90b", 
	text: "\e909", 
	times: "\e918", 
	up: "\e916", 
);

$bg-icons: (
	blackStar: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsLXJ1bGU6IGV2ZW5vZGQ7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxwYXRoIGlkPSJTdGVybl9taXRfNV9aYWNrZW5fMSIgZGF0YS1uYW1lPSJTdGVybiBtaXQgNSBaYWNrZW4gMSIgY2xhc3M9ImNscy0xIiBkPSJNMzItLjA0NEw0Mi41NDYsMjAuMDgyLDY0LDI0LjQxOCw0OS4wNjQsNDEuMTkzLDUxLjc3NSw2NCwzMiw1NC4yMzksMTIuMjI1LDY0bDIuNzEtMjIuODA2TDAsMjQuNDE4bDIxLjQ0OS00LjMzNkwzMi0uMDQ0Ii8+Cjwvc3ZnPgo=", 
	blackTimes: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsLXJ1bGU6IGV2ZW5vZGQ7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxwYXRoIGlkPSJSZWNodGVja18xIiBkYXRhLW5hbWU9IlJlY2h0ZWNrIDEiIGNsYXNzPSJjbHMtMSIgZD0iTTUxLjc1MSw2LjVMNTcuNSwxMi4yNDksMTIuMjQ5LDU3LjUsNi41LDUxLjc1MSw1MS43NTEsNi41Ii8+CiAgPHBhdGggaWQ9IlJlY2h0ZWNrXzFfS29waWUiIGRhdGEtbmFtZT0iUmVjaHRlY2sgMSBLb3BpZSIgY2xhc3M9ImNscy0xIiBkPSJNNTcuNSw1MS43NTFMNTEuNzUxLDU3LjUsNi41LDEyLjI0OSwxMi4yNDksNi41LDU3LjUsNTEuNzUxIi8+Cjwvc3ZnPgo=", 
	blackCheck: "data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FwYV8xIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTUuNTU2IDUxNS41NTYiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTE1LjU1NiA1MTUuNTU2IiB3aWR0aD0iNTEyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDI3NC4yMjYgMTc2LjU0OSAxNzYuODg2IDMzOS4wMDctMzM4LjY3Mi00OC42Ny00Ny45OTctMjkwLjMzNyAyOTAtMTI4LjU1My0xMjguNTUyeiIvPjwvc3ZnPg==", 
	whiteStar: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlN0ZXJuX21pdF81X1phY2tlbl8xIiBkYXRhLW5hbWU9IlN0ZXJuIG1pdCA1IFphY2tlbiAxIiBjbGFzcz0iY2xzLTEiIGQ9Ik0zMi0uMDQ0TDQyLjU0NiwyMC4wODIsNjQsMjQuNDE4LDQ5LjA2NCw0MS4xOTMsNTEuNzc1LDY0LDMyLDU0LjIzOSwxMi4yMjUsNjRsMi43MS0yMi44MDZMMCwyNC40MThsMjEuNDQ5LTQuMzM2TDMyLS4wNDQiLz4KPC9zdmc+Cg==", 
	whiteTimes: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlJlY2h0ZWNrXzEiIGRhdGEtbmFtZT0iUmVjaHRlY2sgMSIgY2xhc3M9ImNscy0xIiBkPSJNNTEuNzUxLDYuNUw1Ny41LDEyLjI0OSwxMi4yNDksNTcuNSw2LjUsNTEuNzUxLDUxLjc1MSw2LjUiLz4KICA8cGF0aCBpZD0iUmVjaHRlY2tfMV9Lb3BpZSIgZGF0YS1uYW1lPSJSZWNodGVjayAxIEtvcGllIiBjbGFzcz0iY2xzLTEiIGQ9Ik01Ny41LDUxLjc1MUw1MS43NTEsNTcuNSw2LjUsMTIuMjQ5LDEyLjI0OSw2LjUsNTcuNSw1MS43NTEiLz4KPC9zdmc+Cg==", 
	whiteCheck: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlJlY2h0ZWNrXzFfS29waWUiIGRhdGEtbmFtZT0iUmVjaHRlY2sgMSBLb3BpZSIgY2xhc3M9ImNscy0xIiBkPSJNMjIuNiw1NS4zMkw2NC4zMiwxMy42LDU4LjY2Myw3Ljk0NCwyMi42LDQ0LjAwNiw2LjMzNywyNy43NDMsMC42OCwzMy40WiIvPgo8L3N2Zz4K", 
);

$grid-map: (
	default: (
		width: 0px, 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
		height100: (height:100%),
	),
	micro: (
		width: 375px, 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
		height100: (height:100%),
	),
	tiny: (
		width: 480px, 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
		height100: (height:100%),
	),
	small: (
		width: 640px, 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
		height100: (height:100%),
	),
	medium: (
		width: 760px, 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
		height100: (height:100%),
	),
	large: (
		width: 992px, 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
		height100: (height:100%),
	),
	giant: (
		width: 1200px, 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
		height100: (height:100%),
	),
	huge: (
		width: 1364px, 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
		height100: (height:100%),
		alignLeft: (text-align:left),
	),
	full: (
		width: 1520px, 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
		height100: (height:100%),
	),
	cut: (
		width: $row-max-width, 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
		height100: (height:100%),
	),
	end: (
		width: 1880px, 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
		height100: (height:100%),
	),
	enormous: (
		width: 2500px, 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
		height100: (height:100%),
	),
);

