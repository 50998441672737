#careerTeam {
    h1 {
        padding-left: 0 !important;

        @include breakpoint(giant) {
            display: table;
            margin-left: auto;
            margin-right: auto;
        }
        @include breakpoint(cut) {
            margin-top: -1em;
        }
    }
    #teamFigures {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            width: 160vw;
            height: 160vw;
            background: linear-gradient(to bottom, color(smooth), color(smooth, 0) 25%);
            border-radius: 50%;
            z-index: -3;

            html.contrast-intensify & {
                background: linear-gradient(to bottom, color(moss, .05), color(moss, 0) 25%);
            }

            @include breakpoint(cut) {
                width: rem(1700px);
                height: rem(1700px);
                bottom: 25%;
            }     
        }

        figure {
            padding: 1.5rem 0;
            padding-right: 1.5rem;

            @include breakpoint(micro) {
                width: 50%;
            }
            @include breakpoint(medium) {
                display: flex;
                align-items: center;
            }
            @include breakpoint(large) {
                justify-content: center;
                width: 100%;
            }
            @include breakpoint(giant) {
                padding: 2rem;
            }

            .imgDrop {
                order: -1;
                flex-shrink: 0;

                @include breakpoint(large, max) {
                    width: clamp(rem(80px), 12vw, rem(120px));
                    height: clamp(rem(80px), 12vw, rem(120px));
                }
            }
            figcaption {
                font-size: rem(14px);
                display: flex;
                
                @include breakpoint(small) {
                    font-size: rem(17px);
                }

                &:before {
                    width: 1em;
                    margin: 0 0.8em;
                    height: 0.7em;
                    border-bottom: $base-border;
                    border-color: color(dark);
                    flex-shrink: 0;
                    display: inline-block;
    
                    @include breakpoint(medium) {
                        content: '';
                        width: clamp(1em, 3vw, rem(64px));
                    }
                }                        

                @include breakpoint(full, max) {
                    > span {
                        max-width: 9em;
                    }
                }
                
                strong, em {
                    display: block;
                }
                strong {
                    line-height: 1.45em;
                    white-space: nowrap;
                }
                em {
                    line-height: 1.45em;
                    font-size: 0.8em;
                    font-weight: 500;
                    margin: 0.2em 0;
                }
            }
    
            &:nth-of-type(1) {
                @include breakpoint(large) {
                    justify-content: flex-end;
                    width: 65%;

                    .imgDrop {
                        width: rem(200px);
                        height: rem(200px);

                        &:not(:hover) {
                            border-radius: 38% 63% 3rem 4rem;
                        }
                    }
                    figcaption {
                        font-size: rem(22px);
                    }
                }
            }
            &:nth-of-type(2) {
                @include breakpoint(large) {
                    width: 35%;
                    transform: translateY(-75%);
                }
                @include breakpoint(full) {
                }
            }
            &:nth-of-type(3) {
                order: -3;

                @include breakpoint(large) {
                    width: 100%;
                    justify-content: flex-start;
                    padding-left: 4%;

                    .imgDrop {
                        width: rem(150px);
                        height: rem(150px);

                        &:not(:hover) {
                            border-radius: 38% 63% 1.5rem 2rem;
                        }
                    }
                    figcaption {
                        font-size: rem(20px);
                    }
                }
                @include breakpoint(giant) {
                    padding-left: 10%;
                }
            }
            &:nth-of-type(4) {
                @include breakpoint(large) {
                    justify-content: flex-start;
                    transform: translateY(30%);
                    width: 50%;
                }
                @include breakpoint(giant) {
                    transform: translateY(-10%);
                    padding-left: 1rem;
                }
                @include breakpoint(huge) {
                    transform: translateY(30%);
                }
            }
            &:nth-of-type(5) {
                @include breakpoint(large) {
                    justify-content: flex-end;
                    width: 50%;
                }
                @include breakpoint(full) {
                    padding-top: 0;

                    .imgDrop {
                        width: rem(200px);
                        height: rem(200px);

                        &:not(:hover) {
                            border-radius: 38% 63% 3rem 4rem;
                        }
                    }
                    figcaption {
                        font-size: rem(22px);
                    }
                }
            }
        }
    }
}