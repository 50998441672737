:root {
	--ruffnav-nav-height: calc(100vh - #{rem($bar-height)});

	@supports (width: 100dvh) {
		--ruffnav-nav-height: calc(100dvh - #{rem($bar-height)});
	}
}

@mixin quickbar-for-desktop($bp-width: min) {
	@if $quickbar-for-desktop == false {
		@if $bp-width == max {
			@include breakpoint($break, $bp-width) {
				@content;
			}
		}

		@else {
			@include breakpoint($break) {
				@content;
			}
		}
	}

	@else {
		@content;
	}
}

@mixin page-navi($class: "#navigation") {
	#{$class} {
		position: fixed;
		max-width: 100%;
		right: 0;
		@content;
	}
}

@mixin ul {
	ul {
		width: 100%;
		@content;
	}
}

@mixin nav-buttons {

	a,
	&:not(.nav-back) > span,
	&.nav-back,
	&.has-sub > span {
		// @extend %animatedTransform;
		display: block;
		cursor: pointer;
		@content;

		html.contrast-intensify & {
			text-decoration: underline;

			&:before, &:after {
				text-decoration: none;
			}
		}
	}

	a,
	&.has-sub > span {
		&.active {
			@extend %button-active;
		}
	}

	&:hover {
		&.nav-back,
		> a,
		&:not(.nav-back) > span {
			@extend %button-hover;
		}
	}

	[role="menuitem"] {
		&:focus {
			@extend %button-hover;
		}
	}
}

@mixin has-sub($class: ".menu-item-has-children") {
	&#{$class} {
		position: relative;
		> span,
		> a {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@content;

			&:after {
				@extend %next-icon;
				margin-left: 0.5em;
				display: inline-block;
			}
		}

		&.active {
			> a,
			> span {
				@extend %button-active;
			}
		}

		> .sub #{$class} {
			> span,
			> a {
				&:after {
					transform: rotate(0);
				}
			}
		}

		@content;
	}
}

@mixin sub($class: ".sub-menu") {
	#{$class} {
		// @extend %animatedTransform;
		position: absolute;
		left: 0;
		top: 0;
		@content;
		visibility: hidden;
		z-index: 1;

		@include quickbar-for-desktop(max) {
			transform: translateX(100%);
		}

		@include quickbar-for-desktop {
			opacity: 0;
			visibility: hidden;
		}

		&.current {
			@include quickbar-for-desktop(max) {
				transform: translateX(0%);
				visibility: visible;
			}

			@include quickbar-for-desktop {
				opacity: 1;
				visibility: visible;
			}
		}

		&:focus {
			outline-offset: rem(-3px);
		}
	}
}

@mixin nav-back {
	&.nav-back {
			// @extend %animatedTransform;
			position: relative;
			@content;

			&:before {
					@extend %prev-icon;
					margin-right: 1em;
					display: inline-block;
			}
	}
}

@mixin desktop-version-functionality {
	#navigation {
			top: 0;
			width: 100%;

			.desktop-hide {
					display: none;
			}

			nav {
					ul {
							&.navi {
									display: flex;
									justify-content: space-between;

									li {
											a,
											&:not(.nav-back) > span,
											.sub:before,
											.nav-back {
													white-space: nowrap;
											}

											&.has-sub {
													position: relative;

													body.cms-backend & {
															&:hover {
																	> .sub {
																			opacity: 1;
																			visibility: visible;
																	}
															}
													}

													.sub {
															top: 100%;
															width: auto;

															.sub {
																	left: 100%;
																	top: 0;
															}
													}
											}

											&.nav-back {
													&:before {
															@include icon($close-icon);
															text-align: center;
															width: 100%;
													}

													span {
															display: none;
													}
											}

											// show sub on hover
											@if $desktop-hover {
													body.desktop & {
															&:hover {
																	> .sub {
																			top: 100%;
																			opacity: 1;
																			visibility: visible;
																			pointer-events: auto;

																			.sub {
																					top: 0;
																			}
																	}
															}
													}
											}
									}
							}
					}
			}
	}
}

@include quickbar-for-desktop(max) {
	#navigation {
		width: clamp(rem(240px), 50%, rem(400px));
		overflow: hidden;

		@if $bar-pos == "top" {
			top: rem($bar-height);
			bottom: 0;
		}

		@else if $bar-pos == "bottom" {
			top: 0;
			bottom: rem($bar-height);
		}

		&.first-level {
			nav {
				min-height: var(--ruffnav-nav-height);
				overflow: hidden;
				overflow-y: auto;
				height: 100vh;
			}
		}

		nav {
			ul {
				&.navi {
					li {
						&.has-sub {
							.sub {
								height: var(--ruffnav-nav-height);
								z-index: 1;

								&.open {
									-webkit-overflow-scrolling: touch;
									overflow: hidden;
									overflow-y: auto;
								}

								&:before {
									content: attr(data-parent-name);
									display: block;
								}
							}
						}
					}
				}
			}

			.mobile-close-button {
				display: block;
				width: 100%;
				font-size: rem(14px);
				line-height: 100%;
				max-width: rem(140px);
				margin: rem($base-gap) auto;
			}
		}
	}

	html.hidden-scroll {
			overflow: hidden;
	}
}

#quickbar {
	position: fixed;
	left: 0;
	width: 100%;
	@extend %animatedTransform;

	@include quickbar-for-desktop(max) {
			height: rem($bar-height);
	}

	@if $bar-pos == "top" {
		top: 0;

		@if $bar-hide {
				body.scrolling-down & {
						transform: translateY(-100%);
				}
		}
	}

	@else if $bar-pos == "bottom" {
		bottom: 0;

		@if $bar-hide {
				body.scrolling-down & {
						transform: translateY(100%);
				}
		}
	}

	label[for="navi-toggled"] {
		display: block;
		cursor: pointer;
		position: relative;

		[role="button"] {
			// outline-offset: -2px;
			display: block;
			width: rem(24px);
			height: rem(24px);
			border-radius: rem($base-border-radius);
			
			> span {
				width: 100%;
				height: 100%;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				align-content: center;
				justify-content: flex-end;

				span {
					display: none;
				}

				&:before,
				&:after {
					content: "";
					display: block;
					background: $bar-color;
					height: 3px;
					margin: .1rem 0;
					transform-origin: center;
					border-radius: 1rem;
					width: rem(24px);
				}
				&:before {
					transition: width 0.5s 0.4s ease-in-out, transform 0.4s ease-in-out, background 0.6s ease-in-out;
				}
				&:after {
					transition: width 0.4s 0.55s ease-in-out, transform 0.4s ease-in-out, background 0.6s ease-in-out;
				}
				&:after {
					width: rem(16px);
				}
 
				.tooltip {
					text-indent: 0;
					left: auto;
					right: calc(100% + #{rem(10px)});
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}
}


		

#navi-toggled {
	display: none;

	&:checked {
		~ * #navigation,
		~ #navigation {
			@include quickbar-for-desktop(max) {
				transform: none;
				visibility: visible;
				opacity: 1;
			}
		}

		~ #quickbar {
			label[for="navi-toggled"] {
				[role="button"] > span {
					&:before,
					&:after {
						transition: width 0.4s 0.2s ease-in-out, transform 0.4s 0.6s ease-in-out, background 0.6s ease-in-out;
						background: color(dark);
					}
					&:before {
						transform: translateY(rem(3px)) rotate(-45deg);
						width: rem(22px);
					}
					&:after {
						transform: translateY(rem(-3px)) rotate(45deg);
						width: rem(22px);
					}
				}
			}
		}
	}
}


//allow transition of navigation element
body.javascript {
	#navigation,
	.sub {
		transition: transform .3s ease, opacity .3s ease;
	}
}

[data-navigation-status] {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}