.post-pagination {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    .pag-previous,
    .pag-next {
        padding: rem($base-gap);

        a {
            @extend .btn;
        }
    }
}