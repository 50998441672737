#careerFilter {
    @include space(170px);
    background: color(light);

    h1 {
        br {
            @include breakpoint(large) {
                display: none;
            }
        }
    }
    p.highlight {
        @include breakpoint(large, max) {
            margin-bottom: rem($base-line-height);
        }
    }
}
#jobloop {
    position: relative;

    > div {
        body:not(.post-type-archive-karriere) & {
            max-height: 50vh;
            overflow-y: auto;
            border-top: $base-border;
            border-bottom: $base-border;
            border-image: linear-gradient(to right, color-lightness(border, -15%), color(border)) 1;
        
            // &::-webkit-scrollbar {
            //     width: 0.5rem;
            //     height: 0.5rem;
            // }
            // &::-webkit-scrollbar-thumb {
            //     background: color(medium);
            //     border-radius: 1rem;
            //     transition: .3s;
            // }
            // &::-webkit-scrollbar-track {
            //     background: color(border);
            //     transition: .3s;
            // }
        }
    }

    &:before {
        content: attr(data-filtered-count) ' Ergebnisse';
        display: block;
        position: absolute;
        bottom: 100%;
        left: 0;
        padding: 1.5em 0;
        font-weight: 700;
        color: color(dark);
        opacity: 0;
        visibility: hidden;

        @include breakpoint(medium) {
            left: 7vw;
        }
        @include breakpoint(large) {
            left: 4.5vw;
        }
        @include breakpoint(cut) {
            position: static;
            padding: 0 0 2em;
        }
    }
    &.showCount.count1:before {
        content: attr(data-filtered-count) ' Ergebnis';
    }
    &.showCount:before {
        opacity: 1;
        visibility: visible;
        transition: .5s ease-in-out;
    }
    
    article {
        .col {
            float: none;
        }

        .jobloopTitle {
            @include breakpoint(large, max) {
                margin-bottom: 1rem;
            }
        }
        
        > a {
            transition: .35s ease-in-out;
            display: block;
            width: 100%;
            padding: 1.5rem 1rem;
            border-top: $base-border;
            border-color: color(dark);
            outline-offset: -4px;
            
            @include breakpoint(micro) {
                padding: 2rem 1.5rem;
            }
            @include breakpoint(small) {
                padding: 2rem;
            }
            @include breakpoint(medium) {
                padding: 2rem 0.7rem;
            }
            @include breakpoint(huge) {
                padding: rem(67px);
                padding-left: 0;
            }
            @include breakpoint(cut) {
                .jobloopTitle,
                .jobloopLink {
                    flex-basis: auto;
                    flex-grow: 0;
                    padding: 0;
                }
                .jobloopTitle {
                    max-width: rem(1200px);
                    width: 65vw;
                }
                .jobloopLink {
                    max-width: 100%;
                    width: rem(270px);
                }
            }

            > .row {
                width: 100%;
                max-width: 100%;
                margin-left: 0;
            }
    
            .h6 {
                color: color(dark);
                font-weight: 700;
                transition: .5s;

                @include breakpoint(medium) {
                    margin-bottom: 0;
                }
    
                ~ p {
                    letter-spacing: 0.04em;
    
                    @include breakpoint(medium, max) {
                        margin-bottom: rem($base-line-height);
                    }
                }
            }
            .btnIcon {
                font-weight: 700;
                color: color(headline);
                transition: .3s;

                @include breakpoint(large, max) {
                    font-size: rem(14px);
                }
                
                &:after {
                    content: map-get($icon-map, 'arrow-right');
                    color: color(soft);
                    font-family: $icon-font;
                    font-weight: 400;
                    width: 3em;
                    height: 3em;
                    line-height: 3em;
                    display: inline-block;
                    vertical-align: middle;
                    color: color(light);
                    background: color(headline);
                    margin-left: 1em;
                    text-align: center;
                    border-radius: 50%;
                    transition: 0.4s ease-in-out;
                }

                html.contrast-intensify & {
                    color: color(action);

                    &:after {
                        background: color(action);
                    }
                }
            }
        }

        > a {
            &:hover,
            &:focus {
                background: #F7F9F9;

                @include breakpoint(full) {
                    padding-left: rem(67px);
                }

                .btnIcon:after {
                    background: color(secondary);
                    transform: scale(1.1);
                }
                

                html.contrast-intensify & {
                    background: color(noir);

                    .h6 {
                        color: color(light);
                    }        
                    .btnIcon {
                        color: color(light);
    
                        &:after {
                            background: color(action);
                        }
                    }
                }
            }
        }
    }
}

#quickJobFinder {
	background: color(olives);
	position: relative;

	body.karriere-template-default.single & {
		height: rem(120px);
        box-sizing: content-box;
        padding: 1em;

		@include breakpoint(huge) {
			height: rem(191px);
		}

        > .row,
        > .row > .col,
        .front-badges {
            height: 100%;
        }
        .front-badges {
            position: relative;
            z-index: 9;
            font-size: clamp(1rem, 6vw, 2.7rem); 
            
            @include breakpoint(huge, max) {
                font-size: 1.7rem;
            }
            @include breakpoint(large) {
                justify-content: flex-start;
            }

            .iu-praxispartner {
                width: 3.85em;
                max-width: rem(187px);
            }
        }
	}
}
#toggleCareerBox {
    margin: 2rem 0;
    color: color(dark);
    font-size: rem(16px);
    font-weight: 500;
    line-height: 1.35em;
    background: color(silk);
    max-width: rem(659px);
    border-radius: 4em;
    padding: 1em 1.7em;
    padding-right: 5em;
    position: relative;
    overflow: hidden;
    transition: 300ms ease-in-out;
    cursor: pointer;

    html.contrast-intensify & {
        border: $base-border;
        border-color: color(dark);
        color: color(dark);
    }

    html.font-size-110 &,
    html.font-size-130 & {
        @include breakpoint(cut, max) {
            text-align: center;
        }
    }

    &:before {
        margin-right: .4em;
        font-size: 1.1em;
        vertical-align: middle;

        html.font-size-110 &,
        html.font-size-130 & {
            @include breakpoint(full, max) {
                display: block;
                margin-right: 0;
                margin-bottom: .4em;
            }
        }
    }
    &:after {
        content: map-get($icon-map, 'down');
        font-family: $icon-font;
        background: color(secondary);
        color: color(light);
        position: absolute;
        top: 0;
        height: 100%;
        right: 0;
        width: 4em;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s;
    }
    
    @include breakpoint(tiny) {
        font-size: rem(20px);
        padding-right: 6.5em;

        &:after {
            width: 5.5em;
        }
    }
    @include breakpoint(small) {
        padding: 1.33em 2.2em;
        padding-right: 8em;

        &:before {
            margin-right: 1em;
        }
        &:after {
            width: 7em;
            line-height: 3.9em;
        }
    }
    @include breakpoint(large) {
        padding-right: 6.5em;

        &:after {
            width: 5.5em;
        }
    }
    @include breakpoint(giant) {
        margin: rem(40px) 0;
    }

    &.open, &:hover, &:focus, &:active {
        background: color(light);

        &:after {
            background: color-lightness(secondary, 9%);
        }
    }
    &.open {
        &:after {
            content: map-get($icon-map, 'times');
        }
    }

    span {
        display: none;

        @include breakpoint(medium) {
            display: inline;
        }
        @include breakpoint(large) {
            display: none;
        }
        @include breakpoint(huge) {
            display: inline;
        }
    }
}
div.select,
#careerBox {
    width: 100%;

    @include breakpoint(large) {
        width: 46% !important;
        max-width: rem(660px);
    }
}
#careerBox {
    background-color: color(light);
    display: inline-block;
    padding: 0 1.5rem;
    box-shadow: 0 0 20px 0 color(noir, .1);
    width: calc(100% - #{rem($base-gap)});
    z-index: 50;
    transition: 300ms ease-in-out;
    position: absolute;
    top: 80%;
    left: rem($base-gap / 2);
    max-height: rem(280px);
    height: 40vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
    }
    &::-webkit-scrollbar-thumb {
        background: color(medium);
        border-radius: 1rem;
        transition: .3s;
    }
    &::-webkit-scrollbar-track {
        background: #F7F9F9;
        transition: .3s;
    }

    html.contrast-intensify & {
        border: $base-border;
    }

    &.hide {
        overflow: hidden;
        transform: translateY(-2.3rem);
        opacity: 0;
        visibility: hidden;
        transition: none;
    }
    
    @include breakpoint(small) {
        top: 90%;
        left: rem($base-gap);
        width: calc(100% - #{rem($base-gap * 2)});
        padding: 0 2.5rem;
    }
    @include breakpoint(large) {
        top: 100%;
    }
    @include breakpoint(giant) {
        padding: 0 rem(66px);
    }
    @include breakpoint(cut) {
        left: 0;
    }

    &.toTop {
        top: auto !important;
        bottom: 80%;

        @include breakpoint(small) {
            bottom: 90%;
        }
        @include breakpoint(large) {
            bottom: 100%;
        }
    }

    li {
        a {
            display: block;
            padding: 1em 0;
            transition: 0.4s ease-in-out;
            border-bottom: $base-border;
            font-weight: bold;
            font-size: rem(16px);
            line-height: 1.45em;

            &:hover, &:focus, &:active {
                color: color(primary);
            }
        }

        &:first-child {
            a {
                padding-top: 1.5em;
            }
        }
        &:last-child {
            a {
                padding-bottom: 1.5em;
                border: none;
            }
        }

        @include breakpoint(small) {
            a {
                padding: 2em 0;
                font-size: rem(18px);
            }

            &:first-child {
                a {
                    padding-top: 3em;
                }
            }
            &:last-child {
                a {
                    padding-bottom: 3em;
                }
            }
        }
        @include breakpoint(giant) {
            &:first-child {
                a {
                    padding-top: 4em;
                }
            }
            &:last-child {
                a {
                    padding-bottom: 4em;
                }
            }
        }
    }
}

#filteredmsg {
    padding: 1.5rem 1rem;
    padding-left: 0 !important;
    
    @include breakpoint(micro) {
        padding: 2rem 1.5rem;
    }
    @include breakpoint(small) {
        padding: 2rem;
    }
    @include breakpoint(medium) {
        padding: 2rem 0.7rem;
    }
    @include breakpoint(huge) {
        padding: rem(67px);
    }
}