#linktree {
    display: flex;
    flex-flow: row wrap;
    margin: 0 rem(5px * -1);
    
    li {
        width: 50%;
        padding: rem(5px);
        
        @include breakpoint(large) {
            width: 33.33333%;
        }
    }
    a {
        display: block;
        padding: 2rem 1rem;
        height: 100%;
        text-align: center;
        background: color(primary);
        color: color(silk);
        font-size: rem(14px);
        font-weight: 700;
        transition: .3s;
        
        @include breakpoint(small) {
            padding: 3rem;
            font-size: rem(20px);
        }
        @include breakpoint(medium) {
            padding: 4rem 3rem;
        }

        html.contrast-intensify & {
            background: color(action);
            color: color(action-color) !important;
            
            &:focus-visible {
                background: color(hover);
                color: color(hover-color) !important;
            }
        }

        &:after {
            content: none;
        }
        img {
            width: auto;
            height: rem(30px);
            transition: 0.5s ease;
            display: block;
            margin: 0 auto 0.3rem;
            
            @include breakpoint(small) {
                margin: 0 auto 1rem;
                height: rem(50px);
            }
            
            &:not([src*="tiktok"]) {
                filter: invert(100%);
            }
        }
        
        &:hover, &:focus, &:active {
            img {
                transform: scale(1.15);
            }
        }
        &[href*="facebook"] {
            background: #1876f2;
        }
        &[href*="instagram"] {
            background: linear-gradient(45deg, #feda75 0%,#fa7e1e 25%,#d62976 50%,#962fbf 75%,#4f5bd5 100%);
        }
        &[href*="pinterest"] {
            background: #E60023;
        }
        &[href*="youtube"] {
            background: #ED1D24;
        }
        &[href*="tiktok"] {
            background: #000;
        }
        &[href*="linkedin"] {
            background: #0077b5;
        }
    }
}