
.categorySelectFilterIcon {
    display: flex;
    align-items: center;
    width: 100%;
    
    &:before {
        background: color(headline);
        color: color(silk);
        width: 3em;
        height: 3em;
        line-height: 3em;
        border-radius: 50%;
        text-align: center;
        flex-shrink: 0;
        order: -2;
    }
    &:after {
        content: '';
        border-bottom: 1px solid color(dark);
        order: -1;
        margin: 0 rem($base-gap);
        display: inline-block;
        
        @include breakpoint(medium) {
            width: rem(40px);
            content: '';
            flex-shrink: 0;
        }
        @include breakpoint(huge) {
            width: rem(100px);
            margin: 0 rem($base-gap * 2);
        }
    }
}
.category_select {
    .children {
        display: none;
    }
    
    &.not_grouped_category_select {
        width: calc(100% - #{rem(54px + ($base-gap * 1.5))});
        flex-grow: 1;
        display: block;
        overflow: visible;

        @include breakpoint(small) {
            width: calc(100% - #{rem(54px + ($base-gap * 2))});
        }
        @include breakpoint(medium) {
            width: calc(100% - #{rem(54px + 40 + ($base-gap * 3))});
        }
        @include breakpoint(huge) {
            width: calc(100% - #{rem(54px + 100 + ($base-gap * 2))});
        }

        li {
            display: inline-block;
            flex-shrink: 0;
            transition: 0.6s ease-in-out;
            opacity: 0;
            pointer-events: none;

            &.swiper-slide-visible {
                opacity: 0.1;
                pointer-events: auto;
            }
            &.swiper-slide-active:not(.swiper-slide-visible),
            &.swiper-slide-fully-visible {
                opacity: 1;
                pointer-events: auto;
            }

            a {
                display: block !important;
            }
        }
    }
    &:not(.grouped_category_select) {
        .category_list {
            display: flex;
            width: 100%;
        
            a, button {
                @extend .btn;
                border: 3px solid color(medium, 0);
                flex-grow: 1;
                margin: 0;
                white-space: nowrap;

                html:not(.font-size-110):not(.font-size-130) & {
                    @include breakpoint(tiny, max) {
                        font-size: 3vw;
                    }
                }
                
                @include breakpoint(tiny, max) {
                    padding: 1.1em 1.8em;
                }
                @include breakpoint(tiny) {
                    font-size: rem(14px);
                    flex-grow: 0;
                }
                @include breakpoint(large) {
                    font-size: rem(16px);
                }
        
            }
            li {
                // &.cat-item {
        
                    // &:hover, &:active, &:focus {
                    //     a, button {
                    //         border-color: color(primary);
                    //         color: color(primary);
                    //         background: none;
                    //     }
                    // }
                // }
                &.cat-item.current-cat a,
                &.cat-item.current-cat button,
                a.active, 
                button.active {
                    background: color(primary) !important;
                    color: color(headline) !important;
                    
                    html.contrast-intensify & {
                        color: color(light) !important;
                    }
                }
            }
        }
    }
}
.termTotal {
    font-weight: 700;
    color: color(dark);
    letter-spacing: 0.04em;
    font-size: rem(12px);
    white-space: nowrap;
    line-height: 1;
    display: flex;
    width: 100%;
    align-items: center;

    @include breakpoint(large, max) {
        display: none;
    }

    span {
        color: color(headline);
        display: inline-block;
        margin-right: 0.3em;
    }

    &:before {
        content: '';
        border-bottom: 1px solid color(dark);
        flex-grow: 1;
        width: 1px;
        order: -1;
        margin: 0 rem($base-gap);
        display: inline-block;
        
        @include breakpoint(huge) {
            margin: 0 rem($base-gap * 2);
        }
    }
}



.category_select_group {
    display: flex;
    width: 100%;
    filter: drop-shadow(0 0 rem(60px) color(noir, .15));
    position: relative;
    z-index: 10;
    border-radius: 9rem;
    
    @include breakpoint(small, max) {
        flex-wrap: wrap;
    }
    @include breakpoint(large, max) {
        margin-top: 1.5rem;
    }
    
    // *, 
    // *:after, 
    // *:before, 
    // .category_list li, 
    // a, 
    // .btn, 
    // .btnWhite {
    //     all: unset;
    // }    

    .category_select {
        background: color(light);    
        flex-grow: 1;
        display: flex;
        position: relative;
        font-size: rem(13px);
        min-height: rem(57px);
        width: 100%;

        @include breakpoint(small) {
            min-height: rem(55px);
        }
        @include breakpoint(medium) {
            font-size: rem(16px);
            min-height: rem(94px);
        }
        @include breakpoint(large) {
            font-size: rem(15px);
            min-height: rem(92px);
        }
        @include breakpoint(giant) {
            font-size: rem(18px);
            min-height: rem(102px);
        }

        html.contrast-intensify & {
            @include breakpoint(small, max) {
                min-height: rem(55px);
            }
        }

        &,
        // span.label,
        .category_list:not(.open),
        .current-cat a {
            border-radius: 0 0 rem(55px / 2) rem(55px / 2);

            @include breakpoint(small) {
                border-radius: 0 rem(55px / 2) rem(55px / 2) 0;
            }
            @include breakpoint(medium) {
                border-radius: 0 rem(94px / 2) rem(94px / 2) 0;
            }
            @include breakpoint(large) {
                border-radius: 0 rem(92px / 2) rem(92px / 2) 0;
            }
            @include breakpoint(giant) {
                border-radius: 0 rem(102px / 2) rem(102px / 2) 0;
            }
        }

        span.label {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 1em 2.5em;
            color: color(headline);
            transition: 0.3s ease-in-out;
            font-weight: 700;
            cursor: pointer;

            html.contrast-intensify & {
                color: color(action);
            }

            @include breakpoint(tiny) {
                padding: 2em 1.7em;
            }
            @include breakpoint(small) {
                padding: 2em 2.5em;
            }

            &:after {
                content: map-get($icon-map, 'down');
                color: color(dark);
                font-family: $icon-font;
                font-weight: 400;
                margin-left: 1em;
            }
        }  
        .category_list {
            position: absolute;
            width: 100%;
            height: auto;
            top: 0;
            transition: max-height 1s ease-in-out;
            display: flex;
            flex-direction: column;
            background: color(light);
            padding-bottom: 1rem;
            overflow: hidden;
            max-height: rem(55px);
            box-sizing: border-box;
            
            @include breakpoint(small) {
                border-radius: 0 rem(55px / 2) 0 0;
            }
            @include breakpoint(medium) {
                border-radius: 0 rem(94px / 2) 0 0;
                max-height: rem(94px);
            }
            @include breakpoint(large) {
                border-radius: 0 rem(92px / 2) 0 0;
                max-height: rem(92px);
            }
            @include breakpoint(giant) {
                border-radius: 0 rem(102px / 2) 0 0;
                max-height: rem(102px);
            }

            html.contrast-intensify & {
                border: $base-border;
                border-color: color(noir);
            }
    
            li {
                display: block;
                list-style-type: none;
                white-space: nowrap;
                transition: .6s;

                &:not(.current-cat) {
                    opacity: 0;
                    visibility: hidden;
                }
                
                a {
                    padding: 0.5em 2.5em;
                    cursor: pointer;
                    display: block;
                    color: color(dark);
                    box-sizing: border-box;
                    white-space: nowrap;

                    @include breakpoint(tiny) {
                        padding: 0.5em 1.7em;
                    }
                    @include breakpoint(small) {
                        padding: 0.5em 2.5em;
                    }
                    
                    &:hover, &:focus-visible {
                        transition: color 0.4s ease-in-out;
                        color: color(primary);
                    }
                }

                &.current-cat {
                    a {
                        padding: 1em 1.4em;
                        color: color(headline);
                        font-weight: 700;
                        display: flex;
                        width: 100%;
                        justify-content: space-around;
                        outline-offset: -4px;
                        
                        @include breakpoint(tiny) {
                            justify-content: space-between;
                        }
                        @include breakpoint(medium) {
                            padding: 2em 1.4em;
                        }
                        @include breakpoint(full) {
                            padding: 2em 2.5em;
                        }

                        &:after {
                            content: map-get($icon-map, 'down');
                            color: color(dark);
                            font-family: $icon-font;
                            font-weight: 400;
                            margin-left: 1em;
                            float: right;
                        }
                    }
                    ~ li {
                        a {
                            padding: 0.5em 2.5em;
                            font-weight: 400;
                            color: color(dark);
                            
                            @include breakpoint(tiny) {
                                padding: 0.5em 1.4em;
                            }
                            @include breakpoint(full) {
                                padding: 0.5em 2.5em;
                            }
                        }
                    }
                }
            }
        }

        &:first-child {
            border-bottom: 2px solid color(dark);
            z-index: 2;
            
            @include breakpoint(small) {
                border-right: 1px solid color(dark);
                border-bottom: none;
            }

            &,
            .category_list:not(.open),
            .current-cat a {
                border-radius: rem(55px / 2) rem(55px / 2) 0 0;
                
                @include breakpoint(small) {
                    border-radius: rem(55px / 2) 0 0 rem(55px / 2);
                }
                @include breakpoint(medium) {
                    border-radius: rem(94px / 2) 0 0 rem(94px / 2);
                }
                @include breakpoint(large) {
                    border-radius: rem(92px / 2) 0 0 rem(92px / 2);
                }
                @include breakpoint(giant) {
                    border-radius: rem(102px / 2) 0 0 rem(102px / 2);
                }
            }

            .category_list.open {
                border-radius: rem(55px / 2) rem(55px / 2) 0 0;

                @include breakpoint(small) {
                    border-radius: rem(55px / 2) 0 0 0;
                }
                @include breakpoint(medium) {
                    border-radius: rem(94px / 2) 0 0 0;
                }
                @include breakpoint(large) {
                    border-radius: rem(92px / 2) 0 0 0;
                }
                @include breakpoint(giant) {
                    border-radius: rem(102px / 2) 0 0 0;
                }
            }
        }
        &.open {
            .category_list {
                max-height: rem(900px);
                box-shadow: 0 0 rem(60px) color(noir, .1);

                li {
                    opacity: 1;
                    visibility: visible;

                    &.current-cat {
                        a:after {
                            content: map-get($icon-map, 'times');
                        }
                    }
                }
            }
        }
    }  
}