// Use this for default Icomoon

@include fontFaceV2(
 $fontName: "icomoon",
 $fileName: "icomoon",
 $types: (woff, ttf, svg, eot),
 $style: normal,
);

// @include fontFaceV2(
//     $fontName: "Red Hat Display", 
//     $fileName: "red-hat-display-v4-latin", 
//     $weights: ("400", "400|italic", 500, "500|italic", 700, "700|italic", 900, "900|italic"), 
//     $types: all
// );

@font-face {
    font-family: "Red Hat Display";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-regular.woff2") format("woff2"), url("/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-regular.woff") format("woff"), url("/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-regular.ttf") format("truetype"), url("/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-regular.svg#RedHatDisplay") format("svg"), url("/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-regular.eot?#iefix") format("embedded-opentype");
  }
  @font-face {
    font-family: "Red Hat Display";
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url("/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-italic.woff2") format("woff2"), url("/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-italic.woff") format("woff"), url("/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-italic.ttf") format("truetype"), url("/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-italic.svg#RedHatDisplay") format("svg"), url("/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-italic.eot?#iefix") format("embedded-opentype");
  }
  @font-face {
    font-family: "Red Hat Display";
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-500.woff2') format("woff2"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-500.woff') format("woff"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-500.ttf') format("truetype"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-500.svg#RedHatDisplay') format("svg"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-500.eot?#iefix') format("embedded-opentype");
  }
  @font-face {
    font-family: "Red Hat Display";
    font-weight: 500;
    font-style: italic;
    font-display: swap;
    src: url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-500italic.woff2') format("woff2"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-500italic.woff') format("woff"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-500italic.ttf') format("truetype"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-500italic.svg#RedHatDisplay') format("svg"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-500italic.eot?#iefix') format("embedded-opentype");
  }
  @font-face {
    font-family: "Red Hat Display";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-700.woff2') format("woff2"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-700.woff') format("woff"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-700.ttf') format("truetype"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-700.svg#RedHatDisplay') format("svg"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-700.eot?#iefix') format("embedded-opentype");
  }
  @font-face {
    font-family: "Red Hat Display";
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    src: url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-700italic.woff2') format("woff2"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-700italic.woff') format("woff"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-700italic.ttf') format("truetype"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-700italic.svg#RedHatDisplay') format("svg"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-700italic.eot?#iefix') format("embedded-opentype");
  }
  @font-face {
    font-family: "Red Hat Display";
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    src: url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-900.woff2') format("woff2"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-900.woff') format("woff"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-900.ttf') format("truetype"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-900.svg#RedHatDisplay') format("svg"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-900.eot?#iefix') format("embedded-opentype");
  }
  @font-face {
    font-family: "Red Hat Display";
    font-weight: 900;
    font-style: italic;
    font-display: swap;
    src: url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-900italic.woff2') format("woff2"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-900italic.woff') format("woff"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-900italic.ttf') format("truetype"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-900italic.svg#RedHatDisplay') format("svg"), url('/wp-content/themes/euroweb2021/fonts/red-hat-display-v4-latin-900italic.eot?#iefix') format("embedded-opentype");
  }