html.contrast-intensify {
    @each $color, $name in $color-map {
        @each $key, $val in $name {
            @if $key == 'contrast' {
                @include define-color($color, map-get($name, 'contrast'));
            }
        }
    }

    @mixin focus-color($color) {
        &:focus {
            outline-color: color($color);
            outline-style: solid;
            outline-width: rem(3px);
            outline-offset: rem(3px);
        }
    }

    form.default {
        ::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: color(medium);
        }
    
        ::-moz-placeholder {
            /* Firefox 19+ */
            color: color(medium);
        }
    
        :-ms-input-placeholder {
            /* IE 10+ */
            color: color(medium) !important;
        }
    
        :-moz-placeholder {
            /* Firefox 18- */
            color: color(medium);
        }
    }
    
    a:not(.btn),
    [tabindex] {
        @include focus-color(alert);
    }

    a,
    button,
    .button,
    .menu-item-has-children > span {
        color: color(action) !important;
        text-decoration: underline;
        font-weight: bold !important;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none !important;
        }
    }

    .btn,
    .button {
        @include focus-color(alert);
        background: color(action) !important;
        color: color(action-color) !important;
        border: 1px solid color(light) !important;

        &:hover {
            outline-color: color(alert);
        }

        &:hover,
        &:focus {
            background: color(hover) !important;
            color: color(hover-color) !important;
            border-color: color(silk) !important;
        }

        @each $color, $name in $color-map {
            @each $key, $val in $name {
                @if $key == 'contrast' {
                    &.btn-#{$color} {
                        &.btn-outline {
                            @include focus-color(alert);
                        }
                    }
                }
            }
        }
    }
}