#comments {
    background: color(light);
}
#commentform {
    fieldset {
        margin: 0;
    }
    .currentAvatar {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;

        .imgDrop {
            flex-shrink: 0;
            margin-right: 1em;
            width: rem(50px);
            height: rem(50px);
            aspect-ratio: 1 / 1;
        }
    }
    .smallSize {
        margin-bottom: 2.5em;
    }
}

.comment {
    font-size: rem(15px);
    line-height: 1.5em;
    padding-bottom: 1em;

    @include breakpoint(medium) {
        margin-left: rem(50px + $base-gap);
        padding-bottom: 2em;
    }

    &:not(:first-of-type) {
        border-top: $base-border;
        border-color: color(sleek, .15);
        padding-top: 1em;

        @include breakpoint(medium) {
            padding-top: 2em;
        }
    }
    &.bypostauthor {
        background: color(medium, .1);
        padding: 1em;
        border-radius: 0 0 2em 0;
        
        @include breakpoint(medium) {
            padding: 2em;
            margin-left: 0;
            padding-left: calc(2em + #{rem(50px + $base-gap)});
        }

        &, + .comment:not(.bypostauthor) {
            border: none;
        }
        + .bypostauthor {
            margin-top: 2px;
        }
    }
    

    a {
        @extend .lineLink;
    }
    .top, .bottom {
        font-size: rem(10px);
        line-height: 1.3em;
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        align-items: center;
        padding-top: 0.7em;
        position: relative;

        + .comment {
            margin-top: 1em;
            
            @include breakpoint(medium) {
                margin-top: 2em;
            }
        }
    }
    .top {
        padding-bottom: 0.7em;
    }
    .comment-author {
        flex-grow: 1;
        display: flex;
        align-items: center;
        
        img {
            border-radius: 50%;
            width: rem(30px);
            height: rem(30px);
            display: inline-block;
            margin-right: 1rem;

            @include breakpoint(medium) {
                position: absolute;
                top: 0;
                right: 100%;
                width: rem(50px);
                height: rem(50px);
            }
        }

        .fn {
            margin: 0;
            hyphens: auto;
            line-height: 1.35em;
        }
    }
    .cancel-comment-reply-link,
    .reply {
        flex-grow: 1;
        text-align: right;
    }
    .comment-reply-link {
        font-weight: 500;
    }
    .comment-meta {
        margin-bottom: 0.2em;
    }
    .comment-date  {
        display: inline-block;
        color: color(medium);
        letter-spacing: 0.1em;
        font-weight: 500;
        text-transform: uppercase;
    }
    .user-comment {
        hyphens: auto;
        
        a {
            word-break: break-all;
        }
    }
}

#respond {
    background: #f7f9f8;

    .comment {
        background: color(primary, .1);
        border-radius: 0 0 2em 0;
        padding: 1em;
        
        @include breakpoint(medium) {
            padding: 2em;
            margin-left: 0;
            padding-left: calc(2em + #{rem(50px + $base-gap)});
        }
    }
}