#numbers {
    @include space(150px);
    z-index: 1;
    position: relative;
    overflow: hidden;

    > * {
        visibility: hidden;
        
        h1, p, li {
            visibility: visible;
        }
    }

    &.lazyLoaded {
        background: linear-gradient(to bottom, color(ice, 0), color(ice)), color(silk) url('/wp-content/themes/euroweb2021/assets/images/numbers/europa.svg') no-repeat center -2px / auto 80%;
        
        @include breakpoint(large) {
            background: linear-gradient(to bottom, color(ice, 0), color(ice)), color(silk) url('/wp-content/themes/euroweb2021/assets/images/numbers/europa.svg') no-repeat calc(100% + 2px) -2px / auto 36rem;
        }
        @include breakpoint(giant) {
            background: linear-gradient(to bottom, color(ice, 0), color(ice)), color(silk) url('/wp-content/themes/euroweb2021/assets/images/numbers/europa.svg') no-repeat calc(100% + 3rem) -2px / auto 43rem;
        }
        @include breakpoint(cut) {
            background: linear-gradient(to bottom, color(ice, 0), color(ice)), color(silk) url('/wp-content/themes/euroweb2021/assets/images/numbers/europa.svg') no-repeat calc(100% + 2px) -2px / auto;
        }
    }

    @include breakpoint(huge) {
        h1 {
            white-space: nowrap;
        }
    }
    
    .numbersList {
        @include breakpoint(medium) {
            @include breakpoint(huge, max) {
                flex-flow: column nowrap;
                align-items: center;
            }
        }
        
        li {
            background: color(light);
            width: rem(568px);
            position: relative; 
            margin: rem($base-gap);
            display: flex;
            align-items: center;
            max-width: calc(100% - #{rem($base-gap * 2)});
            box-shadow: 0 0 rem(20px) color(dark, 0.05);

            html.contrast-intensify & {
                border: $base-border;
            }
    
            @include breakpoint(micro) {
                height: rem(150px);
                width: rem(300px);
    
                @include breakpoint(medium, max) {
                    &:nth-child(even) {
                        margin-left: auto;
                    }
                }
            }
            @include breakpoint(tiny) {
                height: rem(385px / 2);
                width: rem(385px);
            }
            @include breakpoint(medium) {
                height: rem(334px / 2);
                width: rem(334px);
            }
            @include breakpoint(large) {
                height: rem(409px / 2);
                width: rem(409px);
            }
            @include breakpoint(giant) {
                height: rem(504px / 2);
                width: rem(504px);
            }
            @include breakpoint(huge) {
                height: rem(467px / 2);
                width: rem(467px);
            }
            @include breakpoint(cut) {
                height: rem(568px / 2);
                width: rem(568px);
            }
    
            &:first-child {
                box-shadow: 0 0 rem(40px) color(dark, 0.15);
            }
            &:nth-child(2) {
                @include breakpoint(medium) {
                    margin-top: rem($base-gap);
                    margin-right: 36vw;
                }
                @include breakpoint(huge) {
                    transform: translateY(5rem);
                    margin-right: 0;
                }
            }
            &:last-child {
                box-shadow: 0 0 rem(80px) color(dark, 0.2);
                z-index: 1;

                @include breakpoint(medium) {
                    margin-top: rem($base-gap);
                    margin-left: 22vw; 
                }
                
                @include breakpoint(huge) {
                    margin-left: 12rem;
                }
            }
    
            img {
                width: 44%;
                height: 100%;
                object-fit: cover;
                flex-shrink: 0;
                
                @include breakpoint(micro) {
                    width: 50%;
                }
            }
            > div {
                padding: rem(20px);
    
                @include breakpoint(tiny) {
                    padding: rem(29px);
                }
                @include breakpoint(medium) {
                    padding: rem(26px);
                }
                @include breakpoint(giant) {
                    padding: rem(52px);
                }
                @include breakpoint(huge) {
                    padding: rem(42px);
                }
                @include breakpoint(cut) {
                    padding: rem(80px);
                }
            }
            .h2 {
                margin-bottom: 0.1em;
    
                @include breakpoint(large) {
                    font-size: rem(45px);
                }
            }
            .h6 {
                margin: 0;
                
                @include breakpoint(small) {                
                    strong {
                        display: block;
                    }
                }
                @include breakpoint(medium) {
                    @include breakpoint(large, max) {
                        font-size: rem(17px);
                    }
                }
            }
        }
    }

    #locations {
        display: flex;
        overflow-x: auto;
        width: 100%;
        padding: rem($base-gap);

        @include breakpoint(giant, max) {
            visibility: visible;
        }
        @include breakpoint(small) {
            justify-content: center;
        }
        @include breakpoint(giant) {
            position: absolute;
            z-index: -1;
            top: 0;
            right: -3rem;
            width: 100%;
            height: 100%;
        }
        @include breakpoint(cut) {
            right: 0;
        }
        
        > li, 
        > li li {
            position: relative;
            letter-spacing: 0.04em;
            font-size: rem(16px);
            white-space: nowrap;
            line-height: 1;
            color: color(headline);
            font-weight: 700;
            text-align: center;
            display: flex;
            align-items: center;
            
            @include breakpoint(small, max) {
                flex-grow: 1;
            }
            
            &:before {
                @extend .icon;
                @extend .icon-map-marker;
                position: absolute;
                font-size: rem(25px);
                bottom: 100%;
                left: 50%;
                transform: translate(-50%, -150%);
                opacity: 0;
                visibility: hidden;
                z-index: 1;
                transition: transform 0.8s 2s, opacity 0.4s 2s, visibility 0.4s 2s, bottom 0.4s;
            }
            &:after {
                content: '';
                height: 2px;
                background: color(medium);
                width: 1.5em;
                flex-grow: 1;
                margin: 0 1em;
            }
            @include breakpoint(giant, max) {
                &:before,
                &:last-child:after {
                    content: none;
                }

                &.de {
                    ul {
                        display: none;
                    }
                }
            }
            @include breakpoint(giant) {
                flex-wrap: wrap;
                justify-content: center;
                position: absolute;
                
                > span {
                    font-size: 0;
                    color: transparent;
                    width: rem(6px);
                    height: rem(6px);
                    background: color(medium);
                    box-shadow: 0 0 rem(3px) rem(3px) color(medium);
                    border-radius: 50%;
                    transform: scale(0);
                    transition: transform 0.4s 2s, background 0.4s, box-shadow 0.4s;
                }

                &.de {
                    display: contents;

                    &:before {
                        content: none;
                    }
                    > span {
                        display: none;
                    }
                }
                &.brln {
                    top: 7.8rem;
                    right: 29.1rem;

                    &:before {
                        transition: transform 0.8s 2s, opacity 0.8s 2s, visibility 0.8s 2s, bottom 0.4s;
                    }
                    > span {
                        transition: transform 0.8s 2s, background 0.4s, box-shadow 0.4s;
                    }
                }
                &.ddorf {
                    top: 11.4rem;
                    right: 35.4rem;

                    &:before {
                        transition: transform 0.8s 2.2s, opacity 0.8s 2.2s, visibility 0.8s 2.2s, bottom 0.4s;
                    }
                    > span {
                        transition: transform 0.8s 2.2s, background 0.4s, box-shadow 0.4s;
                    }
                }
                &.at {
                    top: 17.5rem;
                    right: 24rem;

                    &:before {
                        transition: transform 0.8s 2.4s, opacity 0.8s 2.4s, visibility 0.8s 2.4s, bottom 0.4s;
                    }
                    > span {
                        transition: transform 0.8s 2.4s, background 0.4s, box-shadow 0.4s;
                    }
                }
                &.ch {
                    top: 20.2rem;
                    right: 36.2rem;

                    &:before {
                        transition: transform 0.8s 2.6s, opacity 0.8s 2.6s, visibility 0.8s 2.6s, bottom 0.4s;
                    }
                    > span {
                        transition: transform 0.8s 2.6s, background 0.4s, box-shadow 0.4s;
                    }
                }
                &.bg {
                    top: 27.6rem;
                    right: 9.6rem;

                    &:before {
                        transition: transform 0.8s 2.8s, opacity 0.8s 2.8s, visibility 0.8s 2.8s, bottom 0.4s;
                    }
                    > span {
                        transition: transform 0.8s 2.8s, background 0.4s, box-shadow 0.4s;
                    }
                }
                
                &:after {
                    width: rem(45px);
                    height: rem(45px);
                    flex-grow: 0;
                    border-radius: 50%;
                    margin: 0;
                    border: 1px solid color(medium);
                    background: none;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) scale(0);
                    transition: 0.4s;
                }

                &:hover, &:active, &:focus {
                    &:before {
                        bottom: 30%;
                    }
                    > span {
                        background: color(primary);
                        box-shadow: 0 0 rem(3px) rem(3px) color(primary);
                    }
                    &:after {
                        transform: translate(-50%, -50%) scale(1);
                        border-color: color(primary);
                    }
                }
            }
            @include breakpoint(cut) {
                &.at {
                    top: 20.7rem;
                    right: 28.8rem;
                }
                &.brln {
                    top: 9.5rem;
                    right: 34.2rem;                    
                }
                &.ddorf {
                    top: 14.2rem;
                    right: 42rem;
                }
                &.ch {
                    top: 24.3rem;
                    right: 43.4rem;
                }
                &.bg {
                    top: 33rem;
                    right: 11.6rem;
                }
            }
        }
    }
    @include breakpoint(giant) {
        &.lazyLoaded {
            #locations {
                > li, 
                > li li {
                    > span {
                        transform: scale(1);
                    }
                    &:before {
                        transform: translate(-50%, -50%);
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}