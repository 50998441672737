#page-transition {
	position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    pointer-events: none;
    background-color: #fff;
    z-index: 9999;
	transition: .1s;

	&:not(.active) {
		opacity: 0;
		visibility: hidden;
	}
}

header,
main,
footer {
	display: block;
	position: relative;
}

// HEADER
//////////////////////////////

.contentHeader,
#header {

	h1,
	.h1 {
		@include breakpoint(small) {
			font-size: rem(50px);
		}

		@include breakpoint(medium) {
			font-size: rem(70px);
		}

		@include breakpoint(large) {
			font-size: rem(90px);
		}

		@include breakpoint(huge) {
			font-size: rem(120px);
		}

		body.post-type-archive-karriere & {
			@include breakpoint(giant) {
				font-size: rem(120px);
				margin-bottom: 0.4em;
			}

			@include breakpoint(huge) {
				font-size: rem(130px);
			}

			@include breakpoint(cut) {
				font-size: rem(160px);
			}
		}

		body:not(.post-type-archive-karriere):not(.home) & {
			@include breakpoint(large) {
				font-size: rem(80px);
			}
		}

		.subline {
			color: inherit;
			padding-left: 0.3em;
		}
	}

	.claim {
		h1,
		.h1 {
			@include breakpoint($break) {
				padding-top: rem(110px);
			}

			@include breakpoint(cut) {
				padding-top: rem(145px);
			}
		}
	}
}

#header {
	body.home & #claim {		
		@include breakpoint(huge) {
			min-height: calc(100vh - 9rem);
			
			@include breakpoint(large) {
				min-height: 83vh;
				
				html.contrast-intensify & {
					min-height: 100vh;
				}
			}
		}
	}

	body.page-id-10743 & .claim,
	body.page-id-10982 & .claim {
		overflow: hidden;
	}

	&,
	#claim {
		display: flex;
		width: 100%;
		flex-flow: column nowrap;
		justify-content: flex-start;
		align-items: stretch;
		align-content: flex-start;
	}

	#claim {
		@include space(120px);
		text-align: center;
		background: color(primary);
		flex-grow: 1;
		justify-content: flex-start;
		position: relative;
		z-index: 1;

		@include breakpoint(huge, max) {
			padding-bottom: 0 !important;

			#toggleCareerBox {
				margin: 1.2rem .6rem;
				max-width: calc(100% - 1.2rem);
				
				@include breakpoint(medium) {
					margin: 2rem 1.2rem;
					max-width: calc(100% - 2.4rem);
				}
			}
		}

		html.contrast-intensify & {
			background: #f8fae9;
		}

		&::after {
			content: '';
			position: absolute;
			inset: 0;
			background: url('/wp-content/themes/euroweb2021/assets/images/layout/wwwe-w.svg') no-repeat center / cover;
			opacity: .1;
			z-index: -1;
			display: block;
			border-radius: inherit;
		}
		body.safari &::after {
			background: url('/wp-content/themes/euroweb2021/assets/images/layout/wwwe-w-white.svg') no-repeat center / cover;
		}
		
		.position-claim {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-grow: 1;
		}
		#toggleCareerBox,
		#careerBox {
			text-align: left;
		}

		.h1,
		.h1::before,
		.highlightBig {
			color: color(dark);
		}
		.h1 {
			margin-top: .7em;

			&::before {
				@include breakpoint(huge) {
					font-size: rem(32px);
				}
			}
		}
	}

	.claim {
		@include space(120px);
		position: relative;
		z-index: 1;
		background: color(primary-soft);

		@include breakpoint(cut) {
			padding-top: rem(202px) !important;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			transform: translate(35%, 50%);
			width: 38vw;
			height: 38vw;
			background: color(medium, .3);
			border-radius: 50%;
			z-index: -1;
			box-shadow:
				0 0 0 14vw color(medium, .1),
				0 0 0 28vw color(medium, .1);

			@include breakpoint(cut) {
				right: 50%;
				transform: translate(calc(#{rem($row-max-width / 2)} + 35%), 50%);
				width: rem(800px);
				height: rem(800px);
				box-shadow:
					0 0 0 rem(220px) color(medium, .1),
					0 0 0 rem(440px) color(medium, .1);
			}
		}

		body.karriere-template-default.single & {
			overflow: hidden;
			
			&:after {
				background: color(mould, .3);
				box-shadow:
					0 0 0 14vw color(mould, .1),
					0 0 0 28vw color(mould, .1);

				@include breakpoint(cut) {
					box-shadow:
						0 0 0 rem(220px) color(mould, .1),
						0 0 0 rem(440px) color(mould, .1);
				}

				html.contrast-intensify & {
					background: color(mould, .09);
					box-shadow:
						0 0 0 14vw color(mould, .03),
						0 0 0 28vw color(mould, .03);
	
					@include breakpoint(cut) {
						box-shadow:
							0 0 0 rem(220px) color(mould, .03),
							0 0 0 rem(440px) color(mould, .03);
					}
				}
			}
		}
	}
}

.contentHeader {
	background: color(primary-soft);
	position: relative;
	z-index: 1;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 50%;
		transform: translate(100%, -110%);
		width: 38vw;
		height: 38vw;
		background: color(medium, .3);
		border-radius: 50%;
		z-index: -1;
		box-shadow:
			0 0 0 14vw color(medium, .1),
			0 0 0 28vw color(medium, .1);

		@include breakpoint(medium) {
			transform: translate(140%, -60%);
		}
		@include breakpoint(giant) {
			transform: translate(150%, 10%);
		}
		@include breakpoint(cut) {
			right: 50%;
			transform: translate(calc(#{$row-max-width / 2} + 35%), 10%);
			width: rem(800px);
			height: rem(800px);
			box-shadow:
				0 0 0 rem(220px) color(medium, .1),
				0 0 0 rem(440px) color(medium, .1);
		}
	}

	.claim {
		@include space(140px);
		position: relative;
		z-index: 1;

		@include breakpoint(cut) {
			padding-top: rem(202px) !important;
		}

		&:after {
			content: '';
			position: absolute;
			height: 100%;
			bottom: 0;
			right: 0;
			width: 100%;
			z-index: -1;
			display: block;
			background: no-repeat center bottom / auto 21rem;
			pointer-events: none;

			@include breakpoint(tiny) {
				background-position: right bottom;
				background-size: 90% auto;
			}
			@include breakpoint(small) {
				background-size: 75% auto;
			}
			@include breakpoint(medium) {
				background-size: 66% auto;
			}
			@include breakpoint(giant) {
				background-size: 60% auto;
			}
			@include breakpoint(huge) {
				background-size: auto;

				html.font-size-130 & {
					@include breakpoint(cut, max) {
						right: -7rem;
					}
				}
			}
			@include breakpoint(cut) {
				right: calc(50% - 22.5rem);
				transform: translateX($row-max-width / 2);
				height: 100%;
				width: rem(1377px);
				background-size: 100% auto;
			}
		}

		@each $staff in ('annika', 'benny', 'fynn', 'tim', 'paulina') {
			&.#{$staff} {
				&:after {
					background-image: url('/wp-content/themes/euroweb2021/assets/images/career/tiny-#{$staff}.png');

					@include breakpoint(tiny) {
						background-image: url('/wp-content/themes/euroweb2021/assets/images/career/small-#{$staff}.png');
					}

					@include breakpoint(small) {
						background-image: url('/wp-content/themes/euroweb2021/assets/images/career/medium-#{$staff}.png');
					}

					@include breakpoint(medium) {
						background-image: url('/wp-content/themes/euroweb2021/assets/images/career/large-#{$staff}.png'); 
					}

					@include breakpoint(large) {
						background-image: url('/wp-content/themes/euroweb2021/assets/images/career/giant-#{$staff}.png');
					}

					@include breakpoint(giant) {
						background-image: url('/wp-content/themes/euroweb2021/assets/images/career/huge-#{$staff}.png');
					}

					@include breakpoint(huge) {
						background-image: url('/wp-content/themes/euroweb2021/assets/images/career/cut-#{$staff}.png');
					}

					@include breakpoint(cut) {
						background-image: url('/wp-content/themes/euroweb2021/assets/images/career/#{$staff}.png');
					}
				}
			}
		}

		blockquote {
			padding: 1rem 1.3rem;
			background: color(silk);
			border-radius: 0 0 0 1.5em;
			max-width: rem(293px);
			letter-spacing: 0.03em;
			font-size: rem(16px);
			line-height: 1.5em;
			position: relative;
			color: color(dark);
			display: inline-block;
			text-align: left;
			margin: 10rem rem($base-gap) 0;

			@include breakpoint(tiny) {
				margin: 7rem rem($base-gap) 3rem;
				padding: 1.5rem 2rem;
			}

			@include breakpoint(small) {
				margin: 4rem rem($base-gap) rem($base-line-height);
			}

			@include breakpoint(large) {
				margin: 4.5rem 0 4rem rem($base-gap * -2);
			}

			@include breakpoint(huge) {
				margin: 3.5rem 0 8rem rem($base-gap * -2);
			}

			body.javascript & {
				opacity: 1 !important;
				transition: .5s 1s;
			}

			// @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
			// 	backdrop-filter: blur(7px) brightness(98%);
			// 	background: color(olives, .6);

			// 	@include breakpoint(enormous) {
			// 		background: color(olives, .1);
			// 	}
			// }

			html.contrast-intensify & {
				// background: color(light) !important;
				border: $base-border;
			}

			&:before {
				display: block;
				background: color(headline);
				width: 2.5em;
				height: 2.5em;
				line-height: 2.5em;
				color: color(silk);
				border-radius: 50%;
				text-align: center;
				position: absolute;
				top: 0;
				right: 0;
				transform: translate(50%, -50%);

				@include breakpoint(huge) {
					font-size: rem(20px);
				}
			}

			strong {
				color: color(headline);
			}

			q {
				font-style: italic;
				font-weight: 500;

				&:before,
				&:after {
					content: none;
				}
			}
		}

		.col.alignCenter {
			@include breakpoint(tiny) {
				text-align: right;
			}

			@include breakpoint(giant) {
				text-align: left;

			}
		}
	}
}

.spaceBadges {
	@include breakpoint(large, max) {
		padding-top: rem($base-line-height) !important;
		padding-bottom: rem($base-line-height) !important;
	}
}
.front-badges {
	font-size: clamp(1rem, 3vw, 3rem);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: .5em;
	padding: 1em 0;
	
	img {
		width: 4em;
	}
	
	$kununu-count: 4;
	.kununu {
		position: relative;
		transform: translate(-.2em, .2em);
		width: 3em;
		margin-right: calc(.5em + .2em * #{$kununu-count});
	
		img {
			border: 1px solid color(flat);
			
			&:nth-child(1) {
				transform: translate(.2em, -.2em);
			}
			@for $i from 2 through $kununu-count {
				&:nth-child(#{$i}) {
					transform: translate(.2em * $i + (.2em * ($i - 1)), -.2em * $i + (-.2em * ($i - 1)));
					position: absolute;
					left: 0;
					top: 0;
					z-index: -1 * $i;
				}
			}
		}
	}
	
	.iu-praxispartner {
		display: inline-block;
	}
}


//////////////////////////////
//////////////////////////////

div[class^="mf_an-welchem-termin-moechtest"] small {
	display: none;
}

@keyframes bubbleRotate {
	0% {
		transform: rotate(0);
	}

	25% {
		transform: rotate(-2deg) scale(1.1);

	}

	100% {
		transform: rotate(0deg);
	}
}

#fixedOpenedDay {
	position: fixed;
	bottom: 7rem;
	left: .5rem;
	z-index: 100;
	width: rem(490px);
	max-width: calc(100% - 1rem);
	box-shadow: 0 0 3rem color(dark, .1);
	transition: .3s ease-in-out;
	border-radius: rem(60px) 0 0 0;
	
	@include breakpoint(tiny) {
		bottom: .5rem;
		max-width: calc(100% - 7.5rem);
	}

	body.javascript & {
		transform: none !important;
		transition-delay: 5s;
	}

	p,
	.btn {
		font-size: clamp(rem(12px), 3vw, rem(16px));
		line-height: 1.35em;
	}

	p {
		font-weight: 400;
	}

	svg {
		path.bubble {
			transform-origin: top right;
			animation: bubbleRotate 5s ease infinite alternate;
		}
	}

	label {
		position: absolute;
		top: 0;
		right: 0;
		color: color(dark);
		width: 3em;
		height: 3em;
		line-height: 3em;
		text-align: center;
		cursor: pointer;
		transition: .3s ease-in-out;

		&:hover {
			background: color(primary);
			color: color(silk);
		}
	}
}

#closeOpenedDay {
	display: none;

	body.javascript &:checked {
		~ #fixedOpenedDay {
			transform: translateX(-150%) !important;
			transition-delay: 0s;
		}
	}
}

//////////////////////////////
//////////////////////////////


// MAIN CONTENT
//////////////////////////////

#content {
	body:not(.home):not(.page-id-10982):not(.page-id-10743):not(.post-type-archive-karriere):not(.single-karriere):not(.single-blog):not(.page-id-11580) & {
		@include space(180px);
		overflow: hidden;
	}
	body:not(.home):not(.page-id-10982):not(.page-id-10743):not(.post-type-archive-karriere):not(.single-karriere):not(.single-blog):not(.page-id-11580):not(.page-id-13637):not(.page-id-13789):not(.page-id-13793) & {
		>.row:first-child {
			@include breakpoint($break) {
				padding-top: rem(110px);
			}

			@include breakpoint(cut) {
				padding-top: rem(145px);
			}
		}
	}

	body.single-blog &,
	// body.post-type-archive-referenzen &,
	body.archive & {
		background: linear-gradient(to bottom, color(silk, .05) 10%, color(silk) 49%);
		overflow: hidden;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			transform: translate(35%, 50%);
			width: 38vw;
			height: 38vw;
			background: #e0e4ed;
			border-radius: 50%;
			z-index: -1;
			box-shadow:
				0 0 0 14vw #e7ecf0,
				0 0 0 28vw #f1f5f6;

			@include breakpoint(cut) {
				right: 50%;
				transform: translate(calc(#{rem($row-max-width / 2)} + 35%), 50%);
				width: rem(800px);
				height: rem(800px);
				box-shadow:
					0 0 0 rem(220px) #e7ecf0,
					0 0 0 rem(440px) #f1f5f6;
			}
		}
	}
}

.content {
	html.font-size-110 &,
	html.font-size-130 & {
		hyphens: auto;
	}

	a {
		html:not(.contrast-intensify) & {
			&:not([href$=".jpg"]):not([href$=".png"]):not([href$=".svg"]):not([href$=".gif"]):not([href*="attachment_id"]):not(.btn) {
				@extend .lineLink;
			}
		}
	}

	p,
	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList),
	table {
		&:not(.noMargin) {
			@extend .marginBottom;
		}

		+h1,
		+h2,
		+h3,
		+h4,
		+h5,
		+h6,
		+.h1,
		+.h2,
		+.h3,
		+.h4,
		+.h5,
		+.h6 {
			padding-top: 2em;
		}
	}

	body.privacy-policy & {
		a {
			word-break: break-all;
		}
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList):not(.gallery-item),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	body.single-blog & img {
		max-width: 100% !important;

		@include breakpoint(tiny) {
			&.floatLeft, 
			&.floatRight {
				max-width: 30% !important;
			}
		}
	}
}

#benefits { 
	background: linear-gradient(to bottom, #F9FAFB, color(soft));
	position: relative;
	overflow: hidden;
	z-index: 4;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(25%, -25%);
		width: 38vw;
		height: 38vw;
		background: color(medium, 0.45);
		border-radius: 50%;
		z-index: -1;
		box-shadow:
			0 0 0 19vw color(medium, 0.17),
			0 0 0 38vw color(medium, 0.17);

		@include breakpoint(cut) {
			right: 50%;
			transform: translate(calc(#{rem($row-max-width / 2)} + 25%), -25%);
			width: rem(630px);
			height: rem(630px);
			box-shadow:
				0 0 0 rem(315px) color(medium, 0.17),
				0 0 0 rem(630px) color(medium, 0.17);
		}
	}

	li {
		display: flex;

		img {
			max-width: 40%;
			height: 100%;
			object-fit: contain;
			object-position: center;
			flex-shrink: 0;
			padding: 32px 30px 32px 0;
			max-height: rem(210px);
			width: rem(230px);

			html.contrast-intensify & {
				filter: grayscale(100%);
			}

			@include breakpoint(tiny) {
				padding: 42px 36px 42px 0;
			}
			@include breakpoint(small) {
				padding: 42px 48px;
			}
			@include breakpoint(medium) {
				padding: 32px 30px 32px 14px;
			}
			@include breakpoint(large) {
				padding: 42px 36px 42px 48px;
			}
			@include breakpoint(giant) {
				padding: 42px 48px;
			}
		}

		span {
			padding: 1em 0;
			border-bottom: 1px solid color(middle, .2);
			display: flex;
			align-items: center;
			hyphens: auto;
		}

		&:last-child {
			span {
				border: none;
			}
		}
	}
}

body.karriere-template-default.single {

	figure.box {
		width: rem(300px);
		max-width: 100%;
		background: color(light);
		border: 1px solid color(flat);
		text-align: left;

		@include breakpoint(huge) {
			display: flex;
			width: 100%;
			align-items: center;
		}

		img {
			flex-shrink: 0;
			object-fit: cover;
			aspect-ratio: 1;

			@include breakpoint(huge) {
				align-self: stretch;
				width: auto;

				@include breakpoint(full, max) {
					width: rem(220px);
				}
			}
		}

		figcaption {
			padding: 2rem 3.1rem 2.5rem;
			font-weight: 500;
			word-break: break-word;

			@include breakpoint(huge) {
				padding: 1.9rem;
			}

			@include breakpoint(full) {
				padding: 2rem rem(56px);
			}

			dt {
				font-weight: 700;
			}

			em {
				font-size: rem(16px);
				line-height: 1.35em;
				display: inline-block;
				padding-bottom: 1.3em;
			}
		}
	}

	.col {
		&.highlightBig {
			text-align: center;

			@include breakpoint(large) {
				text-align: left;
			}
		}

		&.paddingRight {
			@include breakpoint(giant) {
				padding-right: rem(70px);
			}

			@include breakpoint(cut) {
				padding-right: rem(120px);
			}
		}
	}
}

.catContent {
	figure.rCrop {
		@include breakpoint(giant, max) {
			height: rem(300px);
			width: 100%;

			@include breakpoint(medium) {
				width: auto;
				height: 100%;
			}
		}

		img {
			height: 100%;
			object-fit: cover;
		}
	}
}

.transformImg {
	position: relative;
	box-shadow: 0 0 rem(60px) color(noir, .1);
	z-index: 2;

	@include breakpoint(large) {
		margin-top: -18rem;
	}
	@include breakpoint(giant) {
		margin-top: -20rem;
	}

	body.home & {
		@include breakpoint(large) {
			margin-top: -12rem;
		}
		@include breakpoint(huge) {
			margin-top: -16rem;
		}
	}

	body:not(.home) & {
		@include breakpoint(full) {
			margin-top: -25rem;
		}
		@include breakpoint(cut) {
			margin-top: -26.5rem;
		}
	}
	body.page-id-10743 & {
		@include breakpoint(giant) {
			@include breakpoint(full, max) {
				margin-top: -22rem;
			}
		}
	}

	img,
	iframe,
	video {
		width: 100% !important;
		object-fit: cover;
		display: block;
	}
	iframe,
	video {
		aspect-ratio: 16 / 9;
	}
	img {
		height: 100%;
	}
}

body.page-id-10743,
body.page-id-10982 {
	#content {
		.about {
			@include space(200px);
			background: linear-gradient(to bottom, color(light), #F6F6F3);

			.h2 {
				br {
					@include breakpoint(huge, max) {
						display: none;
					}
				}
			}
		}

		.aboutGallery {
			@include space(165px);
			padding-bottom: 0 !important;
			flex-wrap: nowrap;
			transition: 2s 1s;

			&.lazyLoaded {
				transform: translateX(-91%);
			}
		}
	}
}


#privacyPolicy {
	a {
		display: inline-block;
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	.socials {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		transition: 0.5s;
		padding-bottom: rem($base-line-height);

		@include breakpoint(large) {
			display: none;
		}

		li {
			padding: rem($base-gap);
			
			@include breakpoint(large) {
				@include breakpoint(full, max) {
					padding: 0 rem($base-gap) 0 rem($base-gap / 4);
				}
			}
	
			a {
				width: rem(20px);
				display: block;
				transition: 0.4s;
				visibility: visible;
	
				@include breakpoint(large) {
					body.home & {
						filter: invert(100%);
					}
				}
	
				img {
					vertical-align: middle;
					display: block;
				}
	
				&:hover, &:focus, &:active {
					transform: scale(1.2);
				}
			}
		}
	}
}

#companyData {
	@include space(130px);
	position: relative;
	background: color(primary-soft);
	overflow: hidden;

	>.row {
		z-index: 1;
	}

	>article:first-child {
		@include breakpoint(giant) {
			margin-top: rem(40px);
		}
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 38vw;
		height: 38vw;
		background: color(olives, .5);
		border-radius: 50%;
		z-index: 0;
		box-shadow:
			0 0 0 19vw color(olives, .2),
			0 0 0 38vw color(olives, .2);

		@include breakpoint(cut) {
			width: rem(630px);
			height: rem(630px);
			box-shadow:
				0 0 0 rem(315px) color(olives, .2),
				0 0 0 rem(630px) color(olives, .2);
		}

		html.contrast-intensify & {
			background: color(dark, .08);
			box-shadow:
				0 0 0 19vw color(dark, .03),
				0 0 0 38vw color(dark, .03);

			@include breakpoint(cut) {
				box-shadow:
					0 0 0 rem(315px) color(dark, .03),
					0 0 0 rem(630px) color(dark, .03);
			}
		}
	}

	section.marginBottom,
	.col.alignCenter.marginBottom,
	h1~.email.marginBottom {
		margin-bottom: rem($base-line-height * 1.5);

		@include breakpoint(large) {
			margin-bottom: rem(60px);
		}
	}

	.rCrop {
		@include breakpoint(small, max) {
			height: rem(220px);
			width: 100%;

			img {
				width: 100% !important;
			}
		}

		img {
			height: 100%;
			object-fit: cover;
		}
	}

	.footerTeaser {
		@include breakpoint(small) {
			@include breakpoint(large, max) {
				padding-top: 4rem;
				padding-bottom: 2rem;
			}
		}

		section {
			@include breakpoint(medium) {
				@include breakpoint(large, max) {
					padding-right: 2rem;
				}
			}
		}
	}

	.lineLink {
		margin-bottom: 0.5em;

		&:not(:last-of-type) {
			margin-right: 1em;

			@include breakpoint(large) {
				margin-right: 2em;
			}
		}
	}

	@include breakpoint(small) {
		@include breakpoint(giant, max) {
			.h1 {
				font-size: rem(43px);
			}

			h2 {
				font-size: rem(24px);
			}

			#locationsContactData.highlight {
				font-size: rem(16px);
			}
		}
	}
}

#locationsContactData {
	&.highlight {
		@include breakpoint(small, max) {
			@include breakpoint(tiny) {
				font-size: rem(15px);
			}
		}
	}

	.h1 {
		@include breakpoint(giant) {
			font-size: rem(50px);
		}

		br {
			@include breakpoint(medium, max) {
				display: none;
			}
		}
	}

	address {
		br {
			@include breakpoint(medium) {
				display: none;
			}
		}
	}
}



#fixedNotice
{
	display: none;

	+ [for="fixedNotice"]
	{        
		transition:300ms all ease-in-out;
		position: fixed;
		top: 60%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: $base-gap * 3;
		background-color: white;
		max-width: rem(612px);
		width: 85vw;       
		max-height: 50vh; 
		overflow-y: auto;
		z-index: 10000;
		box-shadow: 0 0 100vw 100vw color(dark, .6);
		cursor: pointer;

		&:before 
		{
			transition:300ms all ease-in-out;
			content: "×";
			position: absolute;
			top: $base-gap * 2;
			right: $base-gap * 2;
			color: color(dark-blue);
			font-size: rem(20px);   
			line-height: 1;         
		}
		&:not(:hover):before 
		{
			visibility: hidden;
			opacity: 0;
		}
	}

	&:checked 
	{
		+ [for="fixedNotice"]
		{
			visibility: hidden;
			opacity: 0;
		}
	}
}